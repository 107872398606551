// https://codesandbox.io/s/github/KevinVandy/material-react-table/tree/main/apps/material-react-table-docs/examples/aggregation-and-grouping/sandbox?file=/README.md

import React, { useMemo, useEffect, useState } from 'react';
import { Container, useTheme } from '@mui/material';
import { DataGrid, useGridApiRef, GridToolbarQuickFilter } from '@mui/x-data-grid';

import { useContext } from 'react';
import { ChartDisplayContext } from '../contexts/chartDisplay.context'
import { SimulationDataContext } from '../contexts/simulationData.context'

import '../styles/table.styles.css'

// https://mui.com/x/react-data-grid/filtering/quick-filter/
export const PortfolioTable = ({ tableData }) => {

  const theme = useTheme(); // Get the theme object

  // https://www.material-react-table.com/docs/api/table-instance-apis
  const gridRef = useGridApiRef();

  const { year, setNewYear } = useContext(ChartDisplayContext);


  // filter table data by 'year' when the 'year' context changes
  useEffect( () => {
    console.log('useEffect: year: ', year, ' gridRef:', gridRef);
    if (gridRef.current ) {
      var filterText = ''
      if ( year > 0 ) {
        filterText = year.toString()
      }  
      gridRef.current.setQuickFilterValues( [filterText]  )
    };
  }, [year, gridRef]);

  const columns = [
    { field: 'taxYear',             headerName: 'Année',         flex: 0.6, headerClassName: 'header-class',
        description: 'Year' },
    { field: 'ageStr',              headerName: 'Âges',         flex: 0.5, headerClassName: 'header-class',
        description: 'Ages' },                
    { field: 'person',              headerName: 'Personne',       flex: 0.5, headerClassName: 'header-class',
        description: 'Person' },                
    { field: 'account', headerName: 'Compte', flex: 1.4,  headerClassName: 'header-class' },    
    { field: 
      'movement',
      headerName: 'Rachat',
      cellClassName: 'amount-cell', // Add custom CSS class
      flex: 0.8, 
      type: 'number',
      headerClassName: 'header-class' 
  },
  { field: 
        'endYearTotal',
        headerName: "Fin d'année", 
        cellClassName: 'amount-cell', // Add custom CSS class
        flex: 0.8, 
        type: 'number',
        headerClassName: 'header-class' 
    },
  ];

  const handleFilterModelChange = (model) => {
    if (Object.keys(model.quickFilterValues).length === 0) {
      console.log('filter field:', model.quickFilterValues);
      setNewYear(-1);
    }
  };


  return (
    <Container 
      style={{ height: 'calc(100vh - 200px)' }} 
      overflow="auto" 
      sx ={{
        '& .amount-cell': {
          paddingRight:'10px'
        },
        '& .header-class': {
          backgroundColor: theme.palette.primary.main, // Use the primary color from the theme
          color: theme.palette.primary.contrastText,   // Use the contrasting text color
        }}}>
      <DataGrid 
        apiRef={gridRef}
        rows={tableData}
        columns={columns}
        scrollbarSize={10}
        hideFooterRowCount
        // Cannot disactivate pagination in MIT version of DataGrid
        // pagination={false}    // Disable pagination
        disableColumnResize
        disableColumnMenu
        rowHeight={25}
        // slots={{ toolbar: GridToolbarQuickFilter  }}
        slots={{ toolbar: GridToolbarQuickFilter  }}
        onFilterModelChange={handleFilterModelChange}
      />
    </Container>
  );
}

export default PortfolioTable;
