import React, { useMemo, useContext, useState } from 'react';
import { Grid, Typography, Switch, FormControlLabel } from '@mui/material'; 
import GraphTableExpandButton from '../components/graphTableExpandButton.component';
import NoSimulationData from '../components/noSimulationData.component';
import ResultHeading from '../components/resultHeading.component';

import { SimulationDataContext } from '../contexts/simulationData.context'

import { PageHeading } from '../styles/heading.styles';

import TaxSummaryGraph from '../components/taxSummaryGraph.component';
import TaxSummaryTable from '../components/taxSummaryTable.component';

/*
    taxSummary :  [{
      "taxYear": 2023,
      "botTaxScheme": "IncomeCSG",
      "ref": 1.0,
      "appliesTo": "totalAmount",
      "taxPercent": 17.0,
      "beforeTaxTotal": 35198,
      "taxableTotal": 35198,
      "taxTotal": 5984,
      "taxType": "Earnt"      // or "Invest"
    },
    {
    "taxYear": 2023,
    "botTaxScheme": "incomeTaxBands_2p",
    "ref": 3.0,
    "appliesTo": "totalAmount",
    "taxPercent": 30.0,
    "beforeTaxTotal": 100481,
    "taxableTotal": 93953,
    "taxTotal": 14604,
    "taxType": "Earnt"
  }, ...]
*/

function TaxSummary() {

    const { simulationData } = useContext(SimulationDataContext);
    const [dispTaxScheme, setDispTaxScheme] = useState(false);
    const [expandedComponent, setExpandedComponent] = useState(null);

    const handleExpand = (componentName) => {
      setExpandedComponent(componentName);
    };
  
    const handleReduce = () => {
      setExpandedComponent(null);
    };
  

    /*  ----------------------------------------------------------------------------------
      createKeyTexts : create keyTexts object changes contents depending on whether we are displaying percent or amount

      Description : 
        It maps each key in taxSchemes to itself
        -  when displaying taxSchemes, each key is a taxScheme
        -  when displaying taxAmountTypes, each key is a taxAmountType

      Parameters :
        taxSchemes : array of taxSchemes
        taxAmountTypes : array of taxAmountTypes
        dispTaxScheme : boolean, true when displaying taxSchemes, false when displaying taxAmountTypes

      Returns :
        keyTexts : object with keys and values as described above
      
          dispTaxScheme = true        // display keys corresponding to taxSchemes
              keyTexts = {
                  "IncomeCSG / 1" : "IncomeCSG / 3",
                  "incomeTaxBands_2p / 3" : "incomeTaxBands_2p / 3",
                  "AssVie"  : "AssVie",
                  "ExemptTax" : "ExemptTax",
                  "InvestCSG" : "InvestCSG",
                  "FlatTax" : "FlatTax",
                  "KapitalPension" : "KapitalPension"
                  ...
              }
          dispTaxScheme = false       // display keys corresponding to taxAmountTypes
              keyTexts = {
                  "totalAmount" : "totalAmount",
                  "gainWithdrawn" : "gainWithdrawn",
                  "capitalWithdrawn" : "capitalWithdrawn",
                  ...
                }  
    */
    function createKeyTexts(taxSchemes, taxAmountTypes, dispTaxScheme) {
        const keyTexts = {}

        if (dispTaxScheme) {
            taxSchemes.forEach(key => {
              keyTexts[ key ] = key
            })
        } else {
            taxAmountTypes.forEach(key => {
              keyTexts[ key ] = key
            })  
        }

        // override the keys that we want to display differently
        keyTexts.taxYear = 'Year'
        return keyTexts
    }


    /*  ----------------------------------------------------------------------------------
        useMemo : Create graph and table data from simulationData
    */
    const { tableData, graphData, taxAmountTypes, taxSchemes } = useMemo(() => {
        const numKeys = Object.keys(simulationData).length
        if (numKeys === 0) {
            const tableData = []
            const graphData = []
            return {tableData, graphData}
        }

        console.log('TaxSummary - simulationData["taxSummary"]:', simulationData["taxSummary"]);

        // get max year as numercial value
        const maxYear = parseInt(simulationData['resultSummary']['maxYear'])
                
        const tableData = [];
        const graphData = [];
        const taxSummary = simulationData["taxSummary"]

        /* 
        tableData is an array of objects with the following structure 
        =============================================================
        (same structure as taxSummary)

            tableData : [ {
                "taxYear": 2023,
                "ageStr" : "61 / 53",
                "botTaxScheme": "IncomeCSG",
                "ref": 1.0,
                "appliesTo": "totalAmount",
                "taxPercent": 17.0,
                "beforeTaxTotal": 35198,
                "taxableTotal": 35198,
                "taxTotal": 5984,
                "taxType": "Earnt"      // or "Invest"
            }, ...]

        graphData is an array of objects with the following structure
        =============================================================
            graphData :  [{
                "taxYear": 2023,
                // taxScheme data
                "IncomeCSG / 1" : 5984,
                "incomeTaxBands_2p / 3" :  14604,
                ...
                // tax type data
                "totalAmount" : 6468,
                "capitalGains" : 548,
                ...
                //
                "Earnt" : 36546,
                "Invest" : 5984
            }, ...]        ]

        */

        const taxAmountTypes = new Set();
        const taxSchemes = new Set();

        var lastTaxYear = -1
        var graphDataObject = {}

        taxSummary.forEach((summary, index) => { 
          if ( summary.taxYear <= maxYear ) {

            // create tableData by copying the taxSummary array for all taxYears up to maxYear
            // (could not get the "domain" property of the graph XAxis to work with years)
            tableData.push(summary) 

            // create graphData with one object for each taxYear
            // by grouping the tax schemes, etc by taxYear
            const { taxYear, botTaxScheme, ref, appliesTo, taxType, beforeTaxTotal, taxTotal, taxPercent } = summary;
            if (taxYear !== lastTaxYear) {

              // if this is a new taxYear, create a new graphDataObject
              lastTaxYear = taxYear;
              graphDataObject = { taxYear };
              graphData.push(graphDataObject );
            }
  
            // modify graphDataObject : add the taxTotal to each grouped field
            const taxSchemeKey = botTaxScheme + ' / ' + ref +  ' (' + taxPercent + ')'
            graphDataObject[taxSchemeKey] = (graphDataObject[taxSchemeKey] || 0) + taxTotal;
            graphDataObject[appliesTo] = (graphDataObject[appliesTo] || 0) + taxTotal;
            graphDataObject[taxType] = (graphDataObject[taxType] || 0) + taxTotal;

            // modify tableData : add the taxSchemeKey and id to each row
            summary['taxSchemeKey'] = taxSchemeKey
            summary['id'] = index
            summary['overallPercentTax'] = beforeTaxTotal === 0 ?  0 :  100 * taxTotal / beforeTaxTotal  
          
            // add the taxSchemeKey and appliesTo to the sets
            taxAmountTypes.add(appliesTo);
            taxSchemes.add(taxSchemeKey);
          }
        });
        console.log("taxSummary - taxAmountTypes", taxAmountTypes);
        console.log("taxSummary - taxSchemes", taxSchemes);

        console.log('taxSummary - graphData', graphData);
        console.log('taxSummary - tableData:', tableData);        

        return { tableData, graphData, taxAmountTypes, taxSchemes }
    }, [simulationData]);


    /*  ----------------------------------------------------------------------------------
        useMemo : Create keyTexts object 
                  It  is used to control which data to display on the graph 
                  The keyTexts object changes contents depending on whether 
                  we are displaying taxTypes or taxSchemes
    */
    const keyTexts = useMemo(() => {
      const numKeys = Object.keys(tableData).length
      if (numKeys === 0) {
          return []
      }

      const keyTexts = createKeyTexts(taxSchemes, taxAmountTypes, dispTaxScheme)

      console.log('TaxSummary - keyTexts:', keyTexts);
      return keyTexts
    }, [tableData, taxAmountTypes, dispTaxScheme]);


  const handleSwitchChange = () => {
    setDispTaxScheme(!dispTaxScheme);
    console.log('TaxSummary - dispTaxScheme:', dispTaxScheme ? 'Percent' : 'Amount');
  };


  return (
    <div >
      <Grid container spacing={1}>
      <ResultHeading 
          title="Fiscalité" 
          text="" 
          textBefore="Répartition"
          navigateBefore="/asset-alloc"
          textNext="Liens"
          navigateNext="/podcasts"
        >
        <Grid item xs={2} />
        <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body" paddingRight={2} style={{ display: 'inline-block' }}>Type</Typography>
          <FormControlLabel
              control={
              <Switch
                  checked={dispTaxScheme}
                  onChange={handleSwitchChange}
                  name="graphDataSwitch" />
              } 
            />
          <Typography variant="body" style={{ display: 'inline-block' }} paddingLeft={0} align="left">Régime fiscal</Typography>
        </Grid>
      </ResultHeading>



        {/* if graphData not defined display page with test  "No simulation has been performed" and display link to "inputs" route */}
        { graphData && graphData.length === 0 && (
          <Grid item xs={12}>
            <NoSimulationData />
          </Grid>
        )}


        {graphData && graphData.length > 0 && expandedComponent !== 'Table' && (
          <Grid item xs={expandedComponent === 'Graph' ? 12 : 6} md={expandedComponent === 'Graph' ? 12 : 5} style={{ border: '1px solid black' }}>
            <GraphTableExpandButton handleExpand={handleExpand} handleReduce={handleReduce} expandedComponent={expandedComponent} componentName='Graph' />
            <TaxSummaryGraph graphData={graphData} keyTexts = {keyTexts} />
          </Grid>
        )}        

        {graphData && graphData.length > 0 && expandedComponent !== 'Graph' && (
          <Grid item xs={expandedComponent === 'Table' ? 12 : 6} md={expandedComponent === 'Table' ? 12 : 7} style={{ border: '1px solid black', justifyContent: 'flex-end', overflow: 'hidden' }}>
            <GraphTableExpandButton handleExpand={handleExpand} handleReduce={handleReduce} expandedComponent={expandedComponent} componentName='Table' />
            <TaxSummaryTable tableData={tableData} keyTexts = {keyTexts} />
          </Grid>
        )}

      </Grid>
    </div>
  );
}

export default TaxSummary;