import React from "react";

export default function legendWithRefLines({ payload }) {
    /*
        Create the formatting for a legend so that the legends for the graph bars is on one line,
        and the reference line descriptions are on a separate line.

        The payload is an array of arrays, where each sub-array represents a line of legend items. 
            const payload = [
            [
                { value: "Net Income", type: "square", id: "netIncome", color: "green", dataKey: "netIncome" },
                { value: "Total With Luxury Neg", type: "square", id: "totalWithLuxuryNeg", color: "red", dataKey: "totalWithLuxuryNeg" },
                { value: "Ptf Mvt", type: "line", id: "ptfMvt", color: "black", dataKey: "ptfMvt" },
            ],
            refLineYears.map((refLineYear, idx) => ({
                value: refLineYear.label + ": " + refLineYear.name,
                type: "none",
                id: "refLine" + idx,
                color: "black",
            })),
            ];        

        call this compoent as follows:
        <Legend 
            payload={legendItems}
            content={CustomLegend}
        />
        
    */
    return (
    <div>
        {payload.map((line, i) => (
        <div key={`line-${i}`} style={{ display: "flex", flexWrap: "wrap" }}>
            {line.map((entry, index) => (
            <div
                key={`item-${index}`}
                style={{ color: entry.color, marginRight: "20px" }}
            >
                {entry.type !== "none" && (
                <span
                    style={{
                    display: "inline-block",
                    marginRight: "10px",
                    width: "10px",
                    height: "10px",
                    backgroundColor: entry.color,
                    }}
                ></span>
                )}
                {entry.value}
            </div>
            ))}
        </div>
        ))}
    </div>
    );
}
