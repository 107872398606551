import React from 'react';
import { IconButton } from '@mui/material'; 
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import { useTheme } from '@mui/material/styles';


function GraphTableExpandButton({ handleExpand, handleReduce, expandedComponent, componentName }) {
    const theme = useTheme();

    return (
      <>
      {/* 
        <IconButton 
            onClick={() => handleExpand(componentName)} 
            style={{ display: expandedComponent === componentName ? 'none' : 'inline' }}
            sx={{
                bgcolor: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 0, // make the button square
                "&:hover": {
                  bgcolor: theme.palette.primary.light,
                  color: "#000",
                },
            }}
        >
          <FullscreenIcon />
        </IconButton>
      */}

        <FullscreenIcon
          onClick={() => handleExpand(componentName)}
          style={{ display: expandedComponent === componentName ? 'none' : 'inline', fontSize: 28 }}
          sx={{
            bgcolor: theme.palette.primary.main,
            color: "#fff",
            "&:hover": {
              bgcolor: theme.palette.primary.light,
              color: "#000",
            },
          }}
        />

        <FullscreenExitIcon
          onClick={handleReduce}
          style={{ display: expandedComponent === componentName ? 'inline' : 'none' }}
          sx={{
            bgcolor: theme.palette.primary.main,
            color: "#fff",
            "&:hover": {
              bgcolor: theme.palette.primary.light,
              color: "#000",
            },
          }}
        />

{/* 
        <IconButton 
            onClick={handleReduce} 
            style={{ display: expandedComponent === componentName ? 'inline' : 'none' }}

            sx={{
                bgcolor: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 0, // make the button square
                "&:hover": {
                  bgcolor: theme.palette.primary.light,
                  color: "#000",
                },
            }}
        >
          <FullscreenExitIcon />
        </IconButton>
*/}

        </>
    );
  }
  

export default GraphTableExpandButton;