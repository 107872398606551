import React from 'react';
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

const OverviewButtons = ({ handleCollapseAll, handleExpandAll, expanded, theme }) => {
  return (
    <div style={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>

      <div style={{ marginRight: "10px" }}>
        <UnfoldLessIcon
          onClick={handleCollapseAll}
          disabled={expanded.length === 0}
          style={{ fontSize: 28 }}
          sx={{
            bgcolor: theme.palette.primary.main,
            color: "#fff",
            "&:hover": {
              bgcolor: theme.palette.primary.light,
              color: "#000",
            },
          }}
        />
      </div>

      <div>
        <UnfoldMoreIcon
          onClick={handleExpandAll}
          disabled={expanded.length !== 0}
          style={{ fontSize: 28 }}
          sx={{
            bgcolor: theme.palette.primary.main,
            color: "#fff",
            "&:hover": {
              bgcolor: theme.palette.primary.light,
              color: "#000",
            },
          }}
        />
      </div>
    </div>
  );
};

export default OverviewButtons;