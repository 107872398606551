import React, { useContext } from "react";
import InputWithGrid from "./inputWithGrid.component";
import { useTraceUpdate } from '../../utils/useTraceUpdate.utils';
import { InputPeopleContext } from "../../contexts/inputPeople.context";
import {
  initialPeopleForm,
  peopleCols,
} from "../../common/inputGridColumns.constants";


function InputPeople() {
  const _parent = "InputPeople";
  useTraceUpdate({ _parent: _parent});

  const {
    peopleFormData,
    setPeopleFormData,
    peopleList,
    setPeopleList,
    peopleErrors,
    setPeopleErrors,
  } = useContext(InputPeopleContext);

  return (
          <InputWithGrid
            inputName="Personnes"
            key="people"
            inputCols={peopleCols}
            formData={peopleFormData}
            setFormData={setPeopleFormData}
            dataList={peopleList}
            setDataList={setPeopleList}
            errors={peopleErrors}
            setErrors={setPeopleErrors}
            initialFormData={initialPeopleForm}
            _parent={_parent}
          />
  );
}

export default InputPeople;
