import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Navbar';
import GettingStarted from './routes/gettingStarted';
import Inputs from './routes/inputs';
import Import from './routes/import';
import Overview from './routes/overview';
import Cashflow from './routes/cashflow';
import WithdrawalRates from './routes/withdrawalRates';
import Portfolio from './routes/portfolio';
import AssetAlloc from './routes/assetAlloc';
import TaxSummary from './routes/taxSummary';
import CrashScenario from './routes/crashScenario';
import Podcasts from './routes/podcasts';
import { ThemeProvider, createTheme } from '@mui/material/styles';


// Create a theme with createTheme, changing the primary main color
const theme = createTheme({
  typography: {
    fontFamily: 'sans-serif',
    fontSize: 12,
  },
  palette: {
    primary: {
      main: '#1976d2', // Change this to your desired color
    },
  },
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          '&.chart-overview-style': {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: '300px 300px',
          },
        },
      },
    },
  },
});

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <ThemeProvider theme={theme}>        
        <Routes>
          <Route path="/" element={<GettingStarted />} />
          <Route path="/getting-started" element={<GettingStarted />} />
          <Route path="/inputs" element={<Inputs />} />
          <Route path="/import" element={<Import />} />
          <Route path="/results" element={<Overview />} />
          <Route path="/overview" element={<Overview />} />
          <Route path="/cashflow" element={<Cashflow />} />
          <Route path="/withdrawal-rates" element={<WithdrawalRates />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/asset-alloc" element={<AssetAlloc />} />
          <Route path="/tax-summary" element={<TaxSummary />} />
          <Route path="/crash-scenario" element={<CrashScenario />} />
          <Route path="/podcasts" element={<Podcasts />} />          
        </Routes>
        </ThemeProvider>
      </div>
    </Router>
  );
}

export default App;
