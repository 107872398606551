
import { createContext, useEffect, useMemo, useState } from "react";


// year : is used to synchronise the year on the chart with the table data year
// chart : does not appear to be used

export const DropDownListContext = createContext({
  personDropDownList: [],
  setPersonDropDownList: () => {},
  predefinedDates: [],
  setPredefinedDates: () => {},
});

export const DropDownListProvider = ({ children }) => {

  const [personDropDownList, setPersonDropDownList] = useState([]);
  const [predefinedDates, setPredefinedDates] = useState([]);
  
  const value = useMemo(() => ({
    personDropDownList,
    setPersonDropDownList,
    predefinedDates,
    setPredefinedDates,
  }), [personDropDownList, predefinedDates]);

  useEffect(() => {
    console.log("DropDownListProvider - predefinedDates", predefinedDates);
  }, [predefinedDates]);

  useEffect(() => {
    console.log("DropDownListProvider - personDropDownList", personDropDownList);
  }, [personDropDownList]);

  return (
    <DropDownListContext.Provider value={value}>
      {children}
    </DropDownListContext.Provider>
  );
};
