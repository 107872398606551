import React, { useMemo, useContext, useState } from 'react';
import { Grid, Typography, Switch, FormControlLabel } from '@mui/material'; 
import GraphTableExpandButton from '../components/graphTableExpandButton.component';
import NoSimulationData from '../components/noSimulationData.component';
import ResultHeading from '../components/resultHeading.component';

import { SimulationDataContext } from '../contexts/simulationData.context'

import { PageHeading } from '../styles/heading.styles';

import PortfolioGraph from '../components/portfolioGraph.component';
import PortfolioTable from '../components/portfolioTable.component';

/*
    ptfSummary : [{
      "index": 0,
      "taxYear": 2023,
      "name": "BNP - CTO",
      "person": "Bongo",
      "startYearCapital": 58390.0,
      "startYearTotal": 52312.0,
      "investCapital": 0,
      "gainThisYear": 1341,
      "withdrawCapital": 0,
      "withdrawTotal": 0,
      "endYearCapital": 58390,
      "endYearTotal": 53653,
      "cashEquivAmount": 0.0,
      "percentGainThisYear": 2.6
    }, ...]
*/

function Portfolio() {

  const { simulationData } = useContext(SimulationDataContext);
  const [dispEndYear, setDispEndYear] = useState(true);
  const [expandedComponent, setExpandedComponent] = useState(null);

  const handleExpand = (componentName) => {
    setExpandedComponent(componentName);
  };

  const handleReduce = () => {
    setExpandedComponent(null);
  };

  /*  ----------------------------------------------------------------------------------
    createKeyTexts : create keyTexts object changes contents depending on whether we are displaying yearEnd or movement

    Description : 
      It maps each key in accountTypes to itself
      -  when displaying yearEnd, each key is an accountType
      -  when displaying movement, each key is an accountType + '_mvt'

      Parameters :
      accountTypes : array of accountTypes
      dispEndYear : boolean, true when displaying yearEnd, false when displaying movement

    Returns :
      keyTexts : object with keys and values as described above
    
        dispEndYear = true        // display keys corresponding to enYear amounts
            keyTexts = {
              BNP - CTO: 'BNP - CTO', 
              BNP - Malakof: 'BNP - Malakof', 
              BNP - PEE: 'BNP - PEE', 
              BNP - PERECO: 'BNP - PERECO', 
              …}

        dispEndYear = false        // display keys corresponding to enYear amounts
            keyTexts = {
              BNP - CTO_mvt: 'BNP - CTO', 
              BNP - Malakof_mvt: 'BNP - Malakof', 
              BNP - PEE_mvt: 'BNP - PEE', 
              BNP - PERECO_mvt: 'BNP - PERECO', 
              …}
  */
  function createKeyTexts(accountTypes, dispEndYear) {
    const keyTexts = {}

    accountTypes.forEach(key => {
      keyTexts[ dispEndYear ? key : key + '_mvt' ] = key
    })

    return keyTexts
}
        
        
  /*  ----------------------------------------------------------------------------------
      useMemo : Create graph and table data from simulationData
  */
  const { tableData, graphData, accountTypes } = useMemo(() => {
    const numKeys = Object.keys(simulationData).length
    if (numKeys === 0) {
        const tableData = []
        const graphData = []
        const accountTypes = []
        return {tableData, graphData, accountTypes}
    }

    // get max year as numercial value
    const maxYear = parseInt(simulationData['resultSummary']['maxYear'])
            
    const tableData = [];
    const graphData = [];
    const ptfSummaryData = simulationData["ptfSummary"]
    const personNameDict = simulationData["personNameDict"]
  
    console.log('Portfolio - ptfSummaryData:', ptfSummaryData);
    /* 
    tableData is an array of objects with the following structure 
    =============================================================
    (same structure as taxSummary)

        tableData : [ {
            "taxYear": 2023,
            "ageStr" : "61 / 53",
            "name" : "BNP - CTO",
            "endYearTotal" : 53653,
            "movement" : 300,
            "id" : 2,
        }, ...]

    graphData is an array of objects with the following structure
    =============================================================
        graphData :  [{
            "taxYear": 2023,
            "BNP - CTO": 53653,
            "BNP - Malakof": 2434,
            "BNP - PEE": 131328,
            "BNP - PERECO": 560,
            ...
          }, ...]        ]

    */
   // make unique list of persons
   const persons = new Set();
    ptfSummaryData.forEach((ptfSummary, index) => { 
      const { person } = ptfSummary;
      persons.add(person)
    })



   
    
    const accountTypes = new Set();

    var lastTaxYear = -1
    var graphDataObject = {}
    var id = 0

    ptfSummaryData.forEach((ptfSummary, index) => { 
      const { taxYear, ageStr, person, name, endYearTotal, investCapital, withdrawTotal } = ptfSummary;
      if ( taxYear <= maxYear ) {

        // create tableData by copying the taxptfSummary array for all taxYears up to maxYear
        // (could not get the "domain" property of the graph XAxis to work with years)
        const movement = investCapital - withdrawTotal
        tableData.push( { taxYear: taxYear,
                          ageStr: ageStr,
                          person: person,
                          account: name,
                          endYearTotal: endYearTotal,
                          movement: movement,
                          id: id++,
                        } )

        // create graphData with one object for each taxYear
        // by grouping the accounts etc by taxYear
        if (taxYear !== lastTaxYear) {

          // if this is a new taxYear, create a new graphDataObject
          lastTaxYear = taxYear;
          graphDataObject = { taxYear };
          graphData.push(graphDataObject );
        }

        // add this account to graphDataObject
        const accountWithPerson = name + ' (' + personNameDict[person] + ')'
        graphDataObject[accountWithPerson] = (graphDataObject[accountWithPerson] || 0 ) + endYearTotal;
        graphDataObject[accountWithPerson + '_mvt'] = (graphDataObject[accountWithPerson + '_mvt'] || 0 ) + movement;

        // add this account to accountTypes
        accountTypes.add(accountWithPerson)
      }
    })

    console.log('Portfolio - graphData:', graphData)
    console.log('Portfolio - tableData:', tableData)
    console.log('Portfolio - accountTypes:', accountTypes)

    return {tableData, graphData, accountTypes}
  }, [simulationData])

   /*  ----------------------------------------------------------------------------------
        useMemo : Create keyTexts object 
                  It  is used to control which data to display on the graph 
                  The keyTexts object changes contents depending on whether 
                  we are displaying taxTypes or taxSchemes
    */

  const keyTexts = useMemo(() => {
    const numKeys = Object.keys(tableData).length
    if (numKeys === 0) {
        return []
    }

    const keyTexts = createKeyTexts(accountTypes, dispEndYear)

    console.log('Portfolio - keyTexts:', keyTexts);
    return keyTexts
  }, [tableData, accountTypes, dispEndYear])

  const handleSwitchChange = () => {
    setDispEndYear(!dispEndYear);
    console.log('Portfolio - dispEndYear:', dispEndYear ? 'End Year' : 'Movement');
  };

  return (
    <div >
      <Grid container spacing={1}>
        <ResultHeading 
            title="Portefeuille" 
            text="Valeurs brutes" 
            textBefore="Taux de retrait"
            navigateBefore="/withdrawal-rates"
            textNext="Répartition"
            navigateNext="/asset-alloc"  
          >
            <Grid item xs={2}/>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body" style={{ display: 'inline-block', paddingRight: '2em' }}>Mouvement</Typography>
              <FormControlLabel
                  control={
                  <Switch
                      checked={dispEndYear}
                      onChange={handleSwitchChange}
                      name="graphDataSwitch"
                  />
                  } 
                />
              <Typography variant="body" style={{ display: 'inline-block', paddingLeft: '0em'}} align="left">Fin de l'année</Typography>
            </Grid>
          </ResultHeading>

        {/* if graphData not defined display page with test  "No simulation has been performed" and display link to "inputs" route */}
        { graphData && graphData.length === 0 && (
          <Grid item xs={12}>
            <NoSimulationData />
          </Grid>
        )}

        {graphData && graphData.length > 0 && expandedComponent !== 'Table' && (
          <Grid item xs={expandedComponent === 'Graph' ? 12 : 6} md={expandedComponent === 'Graph' ? 12 : 7} style={{ border: '1px solid black' }}>
            <GraphTableExpandButton handleExpand={handleExpand} handleReduce={handleReduce} expandedComponent={expandedComponent} componentName='Graph' />
            <PortfolioGraph graphData={graphData} keyTexts = {keyTexts}/>
          </Grid>
        )}        

        {graphData && graphData.length > 0 && expandedComponent !== 'Graph' && (
          <Grid item xs={expandedComponent === 'Table' ? 12 : 6} md={expandedComponent === 'Table' ? 12 : 5} style={{ border: '1px solid black', justifyContent: 'flex-end', overflow: 'hidden' }}>
            <GraphTableExpandButton handleExpand={handleExpand} handleReduce={handleReduce} expandedComponent={expandedComponent} componentName='Table' />
            <PortfolioTable tableData={tableData} />
          </Grid>
        )}
      </Grid>

    </div>
  );
}

export default Portfolio;