import React, { useState } from "react";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AddBoxIcon from "@mui/icons-material/AddBox";

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "blue",
  color: "white",
}));

const ColouredTableRow = styled(TableRow)(({ theme, year }) => ({
  padding: "4px",
  margin: "0px",
  backgroundColor: year % 2 === 0 ? theme.palette.grey[200] : "inherit", // Change the color condition as needed
}));

function formatValue(value, format) {
  if (format === "thousandSeparator") {
    return value.toLocaleString();
  } else if (format === "percent") {
    return `${value} %`;
  } else {
    return value;
  }
}

function TextInput({ label, name, value, onChange, disabled = false}) {
  return (
    <TextField
      fullWidth
      type="text"
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      InputProps={{ sx: { height: "2em" } }}
      InputLabelProps={{ sx: { top: "-35%" } }}
      disabled={disabled}
    />
  );
}

function AmountInput({ label, name, value = 0, onChange, disabled = false }) {
  return (
    <TextField
      fullWidth
      type="number"
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      inputProps={{ style: { textAlign: "right" } }}
      InputProps={{ sx: { height: "2em" } }}
      InputLabelProps={{ sx: { top: "-35%" } }}
      disabled={disabled}
    />
  );
}

function DateInput({ label, name, value, onChange, disabled = false, errors }) {
  return (
    <TextField
      fullWidth
      type="text"
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      disabled={disabled}
      onFocus={(e) => {
        e.target.type = "date";
      }}
      onBlur={(e) => {
        e.target.type = "text";
      }}
      error={!!errors.retireDate}
      helperText={errors.retireDate}
      InputProps={{ sx: { height: "2em" } }}
      InputLabelProps={{ sx: { top: "-35%" } }}
    />
  );
}

function SelectInput({ label, name, value, onChange, disabled = false, errors, dropDownList }) {
  return (
    <FormControl fullWidth>
    <InputLabel 
      sx={{ top: "-35%" }}
      error={errors && !!errors[name]}
      helperText={errors && errors[name]}
    >
      {label}
    </InputLabel>
    <Select
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      disabled={disabled}
      error={errors && !!errors[name]}
      helperText={errors && errors[name]}
      sx={{ height: "2em" }}
      MenuProps={{ sx: { marginTop: "32px" } }}
    >
      {dropDownList && dropDownList.map((item, index) => (
        <MenuItem key={index} value={item}>
          {item}
        </MenuItem>
      ))} 
    </Select>
  </FormControl>

  );
}


export {
  HeaderTableCell,
  ColouredTableRow,
  formatValue,
  TextInput,
  AmountInput,
  DateInput,
  SelectInput
};
