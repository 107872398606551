import React from "react";
import { Box, Button, FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import Badge from '@mui/material/Badge';

import Simulate from "./simulate.component";

const ImportButtons = ({
  validImportData,
  showOnlyErrors,
  setShowOnlyErrors,
  loadValidData,
  resetData,
}) => (
  <Grid container alignItems="center">
    <Grid item xs={3}>
      {validImportData && validImportData.dataExists && (
        <FormControlLabel
          control={
            <Switch
              checked={showOnlyErrors}
              onChange={(event) => setShowOnlyErrors(event.target.checked)}
              name="showOnlyErrors"
              color="primary"
            />
          }
          label={
            <Typography style={{ fontSize: '0.8rem' }}>Afficher seulement les erreurs</Typography>
        }
        />
      )}
    </Grid>

    <Grid item xs={5}>
      <Box display="flex" justifyContent="space-between" paddingRight={4}>
        {validImportData.validExists && (
          <>
            <Button
              onClick={loadValidData}
              variant="contained"
              color="primary"
              sx={{ height: "2em" }}
              disabled={!validImportData.validExists} // disable button if there is no valid data
              title={
                !validImportData.validExists ? "No valid data to load" : ""
              } // tooltip if there is no valid data to load
              startIcon={<Badge badgeContent={2} color="error" sx={{ marginRight: '5px' }}/>}
            >
              Charger des données valides
            </Button>

            <Simulate buttonBadgeText="3"/>
          </>
        )}
      </Box>
    </Grid>
    <Grid item xs={4}>
      <Box display="flex" justifyContent="right" paddingRight={0}>
        <Button
          onClick={resetData}
          variant="contained"
          color="primary"
          sx={{ height: "2em" }}
        >
          Réinitialiser
        </Button>
      </Box>
    </Grid>
  </Grid>
);

export default ImportButtons;
