import { createContext, useMemo, useState } from "react";

import { initialExpenseForm } from "../common/inputGridColumns.constants";

// year : is used to synchronise the year on the chart with the table data year
// chart : does not appear to be used

export const InputExpenseContext = createContext({
    expenseFormData: {},
    setExpenseFormData: () => {},
    expenseList: [],
    setExpenseList: () => {},
    expenseErrors: {},
    setExpenseErrors: () => {},
  });

export const InputExpenseProvider = ({ children }) => {

  // expense data
  // ============
  const [expenseFormData, setExpenseFormData] = useState(initialExpenseForm);
  const [expenseList, setExpenseList] = useState([]);
  const [expenseErrors, setExpenseErrors] = useState({});

    const value = useMemo(() => ({   
    expenseFormData,
    setExpenseFormData,
    expenseList,
    setExpenseList,
    expenseErrors,
    setExpenseErrors,
    initialExpenseForm
  }), [expenseFormData, expenseList, expenseErrors]);


  return (
    <InputExpenseContext.Provider value={value}>
      {children}
    </InputExpenseContext.Provider>
  );
};
