
// tooltip displays 'year' in caption, then the values for the data point
const renderTooltip = (props, keyTexts, displayTotal) => {
    if (! props.active || ! props.payload || ! props.payload.length) {
        return null;
    }
    const { payload, label } = props;

    // console.log('renderTooltip - keyTexts: ', keyTexts)
    const totalValue = payload.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);

    return (
        <table 
            className="custom-tooltip" 
            style={{ 
                fontSize: '12px',
                backgroundColor: 'lightgrey', 
                border: '1px solid black', 
                padding: '5px', 
                marginTop: '0px', 
                paddingTop: '0px',
                pointerEvents: 'none' }}>
            <caption 
                style={{  
                    lineHeight: '1.5em', 
                    textAlign: 'center', 
                    backgroundColor: 'white' ,
                    border: '1px solid black',
                    justifyContent: displayTotal ? 'space-between' : 'center'
                                
                }}>
                <span style={{ marginRight: '5px' }}>{label}</span>
                {displayTotal && (
                    <span style={{ marginLeft: '10px' }}>
                    Total: {new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: 0 }).format(totalValue)}
                    </span>
                )}
            </caption>
            <tbody>
                {payload
                .filter((item) => item.value !== 0) // filter out items where value is 0
                .map((item) => (
                <tr key={item.dataKey} style={{ lineHeight: '1em' }}>
                    <th style={{ textAlign: 'left', paddingRight: '1em', color: item.color, fontWeight: 'normal' }}>{keyTexts[item.name]}</th>
                    <td style={{ textAlign: 'right', color: item.color }}>{new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: 1 }).format(item.value)}</td>
                </tr>
                ))}
            </tbody>
        </table>
    )
};    

export default renderTooltip;