import React, { useContext } from "react";
import InputWithGrid from "./inputWithGrid.component";

import {
    initialRevenueForm,
    revenueCols,
    taxSchemeList, 
    adjustPerYearList,   
} from "../../common/inputGridColumns.constants";

import { useTraceUpdate } from '../../utils/useTraceUpdate.utils';
import { InputRevenueContext } from "../../contexts/inputRevenue.context";
import { DropDownListContext } from "../../contexts/dropDownList.context";

function InputRevenue() {
    const _parent = "InputRevenue";
    useTraceUpdate({ _parent: _parent});

    const {
        revenueFormData,
        setRevenueFormData,
        revenueList,
        setRevenueList,
        revenueErrors,
        setRevenueErrors,
    } = useContext(InputRevenueContext);

    const {
        predefinedDates,
        personDropDownList,
    } = useContext(DropDownListContext);

  return (
    <InputWithGrid
        inputName="Revenus"
        key="revenues"
        inputCols={revenueCols}
        formData={revenueFormData}
        setFormData={setRevenueFormData}
        dataList={revenueList}
        setDataList={setRevenueList}
        errors={revenueErrors}
        setErrors={setRevenueErrors}
        initialFormData={initialRevenueForm}
        predefinedDates={predefinedDates}
        personDropDownList={personDropDownList}
        adjustPerYearList={adjustPerYearList}
        taxSchemeList={taxSchemeList}
        _parent={_parent}
    />
  );
}

export default InputRevenue;
