import React from "react";
import { useTheme } from '@mui/material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';

import { useContext } from 'react';
import { ChartDisplayContext } from '../contexts/chartDisplay.context';
import { RefLineYearsContext } from '../contexts/refLineYears.context';
import legendWithRefLines  from './legendWithRefLines.component';
import renderTooltip  from '../utils/chartUtils';

import '../styles/graph.styles.css';


const WithdrawalRatesGraph = ({ graphData, keyTexts}) => {
  

  const { setNewYear } = useContext(ChartDisplayContext);
  const { refLineYears } = useContext(RefLineYearsContext);

  const theme = useTheme();

  console.log('WithdrawalRatesGraph - graphData:', graphData);

  // set year context on singleClick
  // https://github.com/recharts/recharts/issues/1980
  const yearClick = (event) => {
    if (event && event.activeLabel) {
      setNewYear(event.activeLabel)  
    }
  }

  // reset year context to -1 on doubleClick
  const doubleClick = (event) => {
    if (event ) {
        setNewYear(-1)
    }
  }
  
  const paletteColors = Object.values(theme.palette);
  const percentWithdrawnColor = paletteColors[2].main;
  const safeYearsColor = paletteColors[3].main;
  console.log('WithdrawalRatesGraph - palletteColors:', paletteColors);
  const displayTotal = false;

  const legendItems = [
    [
      { id: "0", value: keyTexts['withdrawRate'], type: "square", color: percentWithdrawnColor},
      { id: "1", value: keyTexts['safeYears'], type: "square",  color: percentWithdrawnColor},
    ],
    refLineYears.map((refLineYear, idx) => ({
      value: refLineYear.label + ": " + refLineYear.name,
      type: "none",
      id: "refLine" + idx,
      color: "black",
    })),
  ];

  const minTaxYear = Math.min(...graphData.map(data => data.taxYear));
  const maxTaxYear = Math.max(...graphData.map(data => data.taxYear));
  console.log('minTaxYear:(' + minTaxYear + ') maxTaxYear:(' + maxTaxYear + ')');
  // display the data type of taxYear in graphData
  console.log('typeof taxYear:(' + typeof graphData[0].taxYear + ')');
  console.log('5th taxYear value:(' + graphData[5].taxYear + ')');

  return (
    <div onDoubleClick={doubleClick}>
    <ResponsiveContainer width="100%" aspect={16/9}>

      <LineChart 
        data={graphData} 
        className="no-select"
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        syncId="withdrawalOverview"
        onClick={yearClick}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="taxYear" style={{ fontSize: '12px' }} />
        <Line dataKey="withdrawRate" dot={{ r: 2 }}/>
        <YAxis 
               label={{ value: keyTexts['withdrawRate'], 
                        angle: -90, 
                        position: 'insideLeft', 
                        stroke: {percentWithdrawnColor} }}
                        style={{ fontSize: '12px' }}  />

        <YAxis yAxisId="right" 
               orientation="right" 
               style={{ fontSize: '12px' }} 
               label={{ value: keyTexts['safeYears'], 
                        angle: -90, 
                        position: 'insideRight', 
                        stroke: {safeYearsColor} }} 
                        />
        <Tooltip content={(props) => renderTooltip(props, keyTexts, displayTotal)} />                         
        <Legend 
          payload={legendItems}
          content={legendWithRefLines}
          wrapperStyle={{ fontSize: '12px' }}
        />
        <Line yAxisId="right" 
              type="monotone" 
              dataKey="safeYears"  
              stroke={safeYearsColor} 
              dot={{ r: 2 }}/>
        {refLineYears.map((refLineYear, idx) => {
          const year = parseInt(refLineYear.year);
          /* console.log('label('+ refLineYear.label + ') refLineYear:('+ year + ') name:(' + refLineYear.name + ')' );
          // console log the min and max taxYear values from graphData
          // const minTaxYear = Math.min(...graphData.map(data => data.taxYear));
          const maxTaxYear = Math.max(...graphData.map(data => data.taxYear));
          console.log('minTaxYear:(' + minTaxYear + ') maxTaxYear:(' + maxTaxYear + ')');
          */
          if (graphData.some(data => data.taxYear === year)) {
            console.log('In range : label('+ refLineYear.label + ') refLineYear:('+ year + ') name:(' + refLineYear.name + ')' );
          return (
            <ReferenceLine 
              key={idx}
              x={year}  
              label={{ position: 'top',  value: refLineYear.label, fontSize: 10 }} 
              strokeWidth={1} 
            />
          )
          } else {
            // console.log('Out of range : label('+ refLineYear.label + ') refLineYear:('+ year + ') name:(' + refLineYear.name + ')' );
            return null;
        }
        })}

      </LineChart>
    </ResponsiveContainer>
    </div>
  );
}

  
export default WithdrawalRatesGraph  


