import React from "react";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';

import { useContext } from 'react';
import { ChartDisplayContext } from '../contexts/chartDisplay.context';
import { RefLineYearsContext } from '../contexts/refLineYears.context';
import legendWithRefLines  from './legendWithRefLines.component';
import { useTraceUpdate } from '../utils/useTraceUpdate.utils';


import renderTooltip  from '../utils/chartUtils';

import '../styles/graph.styles.css';

// import onClick handler for year click


export default function CashflowGraph({ graphData, keyTexts }) {
  const _parent = "CashflowGraph";
  useTraceUpdate({_parent: _parent});

  const { setNewYear } = useContext(ChartDisplayContext);
  const { refLineYears } = useContext(RefLineYearsContext);
  

  const displayTotal = false;

  // set year context on singleClick
  // https://github.com/recharts/recharts/issues/1980
  const yearClick = (event) => {
    if (event && event.activeLabel) {
      setNewYear(event.activeLabel)  
    }
  }

  // reset year context to -1 on doubleClick
  const doubleClick = (event) => {
    console.log('portfolio - double click')
    setNewYear(-1)
  }

  
  /*
          {dateList.map((date, idx) => {
          const year = parseInt(date.date.substring(0, 4));
          console.log('idx :',idx, ' date:('+ year + ')' );
          if (year < 2050) {
            console.log('printing reference line : ', year );
        
            return (
              <ReferenceLine 
                key={idx} 
                x={year}  
                stroke="grey" 
                strokeWidth={1} 
                strokeDasharray="3 3"
              />
            )
          }
        }
        )}

  */

  const legendItems = [
    [
      { value: "Revenu net", type: "square", id: "netIncome", color: "green", dataKey: "netIncome" },
      { value: "Dépenses totales", type: "square", id: "totalWithLuxuryNeg", color: "red", dataKey: "totalWithLuxuryNeg" },
      { value: "Évolution du portefeuille", type: "line", id: "ptfMvt", color: "black", dataKey: "ptfMvt" },
    ],
    refLineYears.map((refLineYear, idx) => ({
      value: refLineYear.label + ": " + refLineYear.name,
      type: "none",
      id: "refLine" + idx,
      color: "black",
    })),
  ];
/*
          content={props => {
            const { payload } = props;
            return (
              <div>
                {payload.map((line, i) => (
                  <div key={`line-${i}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {line.map((entry, index) => (
                      <div key={`item-${index}`} style={{ color: entry.color, marginRight: '20px' }}>
                        {entry.type !== 'none' && <span style={{ display: 'inline-block', marginRight: '10px', width: '10px', height: '10px', backgroundColor: entry.color }}></span>}
                        {entry.value}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            );
          }}

*/

  return (
    <div onDoubleClick={doubleClick}>
    <ResponsiveContainer width="100%" aspect={16/9} >
      <ComposedChart
        data={graphData}
        className="no-select"
        syncId="simOverview"
        onClick={yearClick}

        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="taxYear" scale="band" style={{ fontSize: '12px' }} />
        <YAxis tickFormatter={(value) => new Intl.NumberFormat('en-US').format(value)} style={{ fontSize: '12px' }} />
        <Tooltip  content={(props) => renderTooltip(props, keyTexts, displayTotal)} /> 
        <Legend 
          payload={legendItems}
          content={legendWithRefLines}
          wrapperStyle={{ fontSize: '12px' }}
        />
        <Bar type="monotone" dataKey="netIncome" stackId="1" stroke="green" fill="green82ca9d" />
        <Bar type="monotone" dataKey="totalWithLuxuryNeg" stackId="2" stroke="red" fill="red" />
        <Line type="monotone" dataKey="ptfMvt" stroke="black" dot={{ r: 2 }} />

        {refLineYears.map((refLineYear, idx) => {
          const year = parseInt(refLineYear.year);
          console.log('label('+ refLineYear.label + ') refLineYear:('+ year + ') name:(' + refLineYear.name + ')' );
          return (
            <ReferenceLine 
              key={idx}
              x={year}  
              label={{ position: 'top',  value: refLineYear.label, fontSize: 10 }} 
              strokeWidth={1} 
            />
          )
        }
        )}
      </ComposedChart>
      </ResponsiveContainer>
      </div>
  );
}

  
  


