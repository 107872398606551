// https://codesandbox.io/s/github/KevinVandy/material-react-table/tree/main/apps/material-react-table-docs/examples/aggregation-and-grouping/sandbox?file=/README.md

import React, { useContext, useEffect } from 'react';
import { Container, useTheme } from '@mui/material';
import { DataGrid, useGridApiRef, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { ChartDisplayContext } from '../contexts/chartDisplay.context'

import '../styles/table.styles.css'


// https://mui.com/x/react-data-grid/filtering/quick-filter/
export const TaxSummaryTable = ({ tableData}) => {
  const theme = useTheme(); // Get the theme object

  console.log('TaxSummaryTable - tableData:', tableData);
  
  // https://www.material-react-table.com/docs/api/table-instance-apis
  const gridRef = useGridApiRef();

  const { year, setNewYear } = useContext(ChartDisplayContext);


  // filter table data by 'year' when the 'year' context changes
  useEffect( () => {
    console.log('useEffect: year: ', year, ' gridRef:', gridRef);
    if (gridRef.current ) {
      var filterText = ''
      if ( year > 0 ) {
        filterText = year.toString()
      }  
      gridRef.current.setQuickFilterValues( [filterText]  )
    };
  }, [year, gridRef]);

  const columns = [
    { field: 'taxYear',             headerName: 'Année',         flex: 0.6, headerClassName: 'header-class',
        description: 'Année' },
    { field: 'ageStr',              headerName: 'Âges',         flex: 0.5, headerClassName: 'header-class',
        description: 'Âges' },                
    { field: 'appliesTo',           headerName: 'Type',         flex: 0.8,   headerClassName: 'header-class', 
        description: 'La partie imposée' },
    { field: 'taxSchemeKey',        headerName: 'Régime fiscal',   flex: 1.4,   headerClassName: 'header-class', 
        description: 'Régime fiscal et ligne de référence associée (niveau inférieur)' },
    { field: 'beforeTaxTotal',      headerName: 'Avant impôt',   flex: 0.8,   headerClassName: 'header-class', cellClassName: 'amount-cell', type: 'number',
        description: 'Montant brut avant taxes' },
    { field: 'taxTotal',            headerName: 'Impôt payé',     flex: 0.8,   headerClassName: 'header-class', cellClassName: 'amount-cell', type: 'number',
        description: "Montant total de l'impôt payé" },
    { field: 'overallPercentTax',   headerName: '%',            flex: 0.5,   headerClassName: 'header-class', cellClassName: 'percent-cell', type: 'number',
        valueFormatter: ({ value }) => {    if (value === null) { return ""; }
                                            return `${value.toFixed(1)} %`;
                                        },
        description: "Pourcentage global d'impôt payé sur ce montant" },

];


  const handleFilterModelChange = (model) => {
    if (Object.keys(model.quickFilterValues).length === 0) {
      console.log('filter field:', model.quickFilterValues);
      setNewYear(-1);
    }
  };

  return (
    <Container 
      style={{ height: 'calc(100vh - 200px)' }} 
      overflow="auto" 
      sx ={{
        '& .amount-cell': {
          paddingRight:'10px'
        },
        '& .header-class': {
          backgroundColor: theme.palette.primary.main, // Use the primary color from the theme
          color: theme.palette.primary.contrastText,   // Use the contrasting text color
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          overflowWrap: 'break-word',
          overflow: 'hidden',
      

        }}}>
      <DataGrid 
        apiRef={gridRef}
        rows={tableData}
        columns={columns}
        scrollbarSize={10}
        hideFooterRowCount
        showColumnRightBorder={true} // Add this line to show column descriptions as tooltips

        // Cannot disactivate pagination in MIT version of DataGrid
        // pagination={false}    // Disable pagination
        disableColumnResize
        disableColumnMenu
        rowHeight={25}
        // slots={{ toolbar: GridToolbarQuickFilter  }}
        slots={{ toolbar: GridToolbarQuickFilter  }}
        onFilterModelChange={handleFilterModelChange}
      />
    </Container>
  );
}

export default TaxSummaryTable;
