import React, { useMemo, useContext, useState } from 'react';
import {  Grid } from '@mui/material'; 

import ResultHeading from '../components/resultHeading.component';

import GraphTableExpandButton from '../components/graphTableExpandButton.component';
import NoSimulationData from '../components/noSimulationData.component';

import { SimulationDataContext } from '../contexts/simulationData.context'

import CashflowGraph from '../components/cashflowGraph.component';
import CashflowTable from '../components/cashflowTable.component';


// import CASHFLOW_DATA from '../assets/cashflow_records.json'

/*
     cashflow : [{
      "taxYear": 2023,
      "totalWithLuxuryNeg": -77648,
      "netIncome": 79894.0,
      "ptfMvt": 2021,
      "portfolioGainPercent": 3.8,
      "percentBrutWithdrawn": 0.0,
      "portfolioTotal": 1582661,
      "cashEquivAmount": 502286.0
    }, ...]

    expense : [{
      "taxYear": 2023,
      "Avance Bongo": null,
      "Avance Juju": null,
      "Basic Expenses": -40810.0,
      "Car": null,
      "CityZen expenses": -1444.0,
      "Foncier CityZen": -910.0,
      "Foncier Plaisir": -1704.0,
      "Loan CityZen": -9460.0,
      "Luxuries": -23320.0
    }, ...]

    revenue : [{
      "taxYear": 2023,
      "Balny": 15137.0,
      "CityZen": 5578.0,
      "Invalid Pension": 8715.0,
      "JujuINSERM": 10528.0,
      "SCPI": 3028.0,
      "SellCityZen": null,
      "chomage": 18500.0,
      "prevoyance": 13144.0,
      "retraiteJuju_FR": null,
      "retraiteLars_DK": null,
      "retraiteLars_FR": null,
      "salaryJuju": 5264.0
    }, ...]

*/

function Cashflow() {

  const { simulationData } = useContext(SimulationDataContext);
  const [expandedComponent, setExpandedComponent] = useState(null);

  const handleExpand = (componentName) => {
    setExpandedComponent(componentName);
  };

  const handleReduce = () => {
    setExpandedComponent(null);
  };


    /*  ----------------------------------------------------------------------------------
        useMemo : Create graph and table data from simulationData
    */
    const { tableData, graphData} = useMemo(() => {
      const numKeys = Object.keys(simulationData).length
      if (numKeys === 0) {
          const tableData = []
          const graphData = []
          return {tableData, graphData}
      }

      console.log('Cashflow - simulationData:', simulationData);

      console.log('Cashflow - simulationData["taxSummary"]:', simulationData["taxSummary"]);

      // get max year as numercial value
      const maxYear = parseInt(simulationData['resultSummary']['maxYear'])
              

      /* 
      tableData is an array of objects with the following structure 
      =============================================================
      (revenue and expense are appendded by taxYear)

          tableData : [ {
              "taxYear": 2023,
              "ageStr" : "60 / 52",
              "amount" : -40810,
              "name" : "Basic Expenses",
              "type" : "expense",
              "id" : 1,
          }, {
              "taxYear": 2023,
              "ageStr" : "60 / 52",
              "amount" : 10528,
              "name" : "JujuINSERM",
              "type" : "revenue",
              "id" : 11,
            }, ...]

      graphData is an array of objects with the following structure
      =============================================================
          graphData :  [{
              "taxYear": 2023,
              "ageStr" : "60 / 52",
              "netIncome" : 79894,
              "totalWithLuxuryNeg" : -77648,
              "ptfMvt" : 2021,
              "cashEquivAmount" : 502286,
              "portfolioTotal" : 1582661,
              "percentBrutWithdrawn" : 0,
              "portfolioGainPercent" : 3.8,
          }, ...]                   
      */



      // create graphData
      // ===============
      const graphData = [];
      const cashflowData = simulationData["cashflow"]

      cashflowData.forEach((cashflow, index) => { 
        if ( cashflow.taxYear <= maxYear ) {

          // create tableData by copying the taxcashflow array for all taxYears up to maxYear
          // (could not get the "domain" property of the graph XAxis to work with years)
          graphData.push(cashflow) 

        }
      });

      // create tableData
      // ===============
      const tableData = [];
      var id = 0
      const revenueData = simulationData["revenue"]
      const expenseData = simulationData["expense"]
      const ageStrArray = simulationData["ageStrArray"]

      // add revenue and expense data
      const dataSources = [revenueData, expenseData];

      dataSources.forEach((dataSource, sourceIdx) => {
        dataSource.forEach((data, index) => {
          const taxYear = data.taxYear
          if ( data.taxYear <= maxYear ) {
            Object.keys(data).forEach(key => {
              if (key !== 'taxYear' && key !== 'ageStr' && data[key] !== null) {
                tableData.push({
                  "taxYear": taxYear,
                  "ageStr" : data.ageStr,
                  "amount" : data[key],
                  "name" : key,
                  "type" : sourceIdx === 0 ? "revenue" : "expense",
                  "id" : id++,
                })
              }
            })
          }
      })

      // sort tableData by taxYear, type and name  
      tableData.sort((a, b) => {
        if (a.taxYear < b.taxYear) { return -1;} 
        else 
          if (a.taxYear > b.taxYear) { return 1; } 
          else { // a.taxYear === b.taxYear
            if (a.type < b.type) { return -1; } 
            else 
              if (a.type > b.type) { return 1; }
              else { // a.type === b.type
                if (a.name < b.name) { return -1; }
                else 
                  if (a.name > b.name) { return 1; } 
                  else { return 0;}
              }   
          }
      });
    })
          
    console.log('cashflow - graphData', graphData);
    console.log('cashflow - tableData:', tableData);        

    return { tableData, graphData}
  }, [simulationData]);


  // convert the keys in the data to these display values
  const keyTexts = {
    "totalWithLuxuryNeg": "dépenses",
    "netIncome": "revenu",
    "ptfMvt": "évolution du portefeuille"
  }

  return (
    <div >
      <Grid container spacing={1}>
        <ResultHeading 
          title="Flux de trésorerie" 
          text="Revenus net d'impôts, rachats bruts de portefeuille (avant impôts)." 
          textBefore="Résumé"
          navigateBefore="/overview"
          textNext="Taux de retrait"
          navigateNext="/withdrawal-rates"
          />

        {/* if graphData not defined display page with test  "No simulation has been performed" and display link to "inputs" route */}
        { graphData && graphData.length === 0 && (
          <Grid item xs={12}>
            <NoSimulationData />
          </Grid>
        )}

        {graphData && graphData.length > 0 && expandedComponent !== 'Table' && (
          <Grid item xs={expandedComponent === 'Graph' ? 12 : 6} md={expandedComponent === 'Graph' ? 12 : 8} style={{ border: '1px solid black' }}>
            <GraphTableExpandButton handleExpand={handleExpand} handleReduce={handleReduce} expandedComponent={expandedComponent} componentName='Graph' />
            <CashflowGraph graphData={graphData} keyTexts = {keyTexts}/>
          </Grid>
        )}        

        {graphData && graphData.length > 0 && expandedComponent !== 'Graph' && (
          <Grid item xs={expandedComponent === 'Table' ? 12 : 6} md={expandedComponent === 'Table' ? 12 : 4} style={{ border: '1px solid black', justifyContent: 'flex-end', overflow: 'hidden' }}>
            <GraphTableExpandButton handleExpand={handleExpand} handleReduce={handleReduce} expandedComponent={expandedComponent} componentName='Table' />
            <CashflowTable tableData={tableData} />
          </Grid>
        )}

      </Grid>
    </div>
  );
}

export default Cashflow;