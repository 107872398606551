import { createContext, useState, useMemo, useEffect } from 'react';
// import { data } from '../utils/backendComm.utils'

export const SimulationDataContext = createContext({
  simulationData: {},
});

  // const totalWithLuxuryNeg = Object.values(CHART_DATA['totalWithLuxuryNeg']);

export const SimulationDataProvider = ({ children }) => {
  const [simulationData, setSimulationData] = useState({});

  /*
  useEffect(() => {
    console.log(simulationData);
    if ( ! simulationData.length ) {
      setSimulationData( data );
    }
    
  }, [data]);
  */
  const value = useMemo(() => ({
    simulationData,
    setSimulationData,
  }), [simulationData]);

  return <SimulationDataContext.Provider value={value}>{children}</SimulationDataContext.Provider>;
};
