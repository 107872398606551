import { createContext, useMemo, useState } from "react";

import {
  initialAccounts,
  initialHoldings,
} from "../common/inputGridColumns.constants";

// year : is used to synchronise the year on the chart with the table data year
// chart : does not appear to be used

export const InputPortfolioContext = createContext({
  accounts: [],
  setAccounts: () => {},
  initialAccounts: [],
  holdings: [],
  setHoldings: () => {},
  initialHoldings: [],
  accountErrors: {},
  setAccountErrors: () => {},
});

export const InputPortfolioProvider = ({ children }) => {
  // portfolio data
  // ==============
  const [accounts, setAccounts] = useState([]);
  const [holdings, setHoldings] = useState([]);
  const [accountErrors, setAccountErrors] = useState({});

  const value = useMemo(
    () => ({
      accounts,
      setAccounts,
      initialAccounts,
      holdings,
      setHoldings,
      initialHoldings,
      accountErrors,
      setAccountErrors,
    }),
    [accounts, holdings, accountErrors]
  );

  return (
    <InputPortfolioContext.Provider value={value}>
      {children}
    </InputPortfolioContext.Provider>
  );
};
