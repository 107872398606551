import React, { useMemo, useContext, useState } from 'react';
import { Grid, Typography, Switch, FormControlLabel } from '@mui/material'; 
import GraphTableExpandButton from '../components/graphTableExpandButton.component';
import NoSimulationData from '../components/noSimulationData.component';
import ResultHeading from '../components/resultHeading.component';

import { SimulationDataContext } from '../contexts/simulationData.context'

import { PageHeading } from '../styles/heading.styles';

import AssetAllocGraph from '../components/assetAllocGraph.component';
import AssetAllocTable from '../components/assetAllocTable.component';

/*  
    assetAlloc : [ {
      "taxYear": 2023,
      "ageStr" : "61 / 53",
      "Bonds": 32.0791376043,
      "Emerging Markets": 8.7762319284,
      "Europe": 14.0142456281,
      "French": 16.1767428401,
      "International": 20.0466808748,
      "Japan": 1.1540690015,
      "Real Estate": 6.0381850567,
      "Structured": 0.5852169226,
      "Technology": 1.1294901435
    }, ...]

*/

function AssetAlloc() {

    const { simulationData } = useContext(SimulationDataContext);
    const [isPercent, setIsPercent] = useState(false);
    const [expandedComponent, setExpandedComponent] = useState(null);

    const handleExpand = (componentName) => {
      setExpandedComponent(componentName);
    };
  
    const handleReduce = () => {
      setExpandedComponent(null);
    };
  

    /* createKeyTexts : 
        
      Description : 
        Create keyTexts object changes contents depending on whether we are displaying percent or amount
        It maps each key in supportTypes to itself
        -  when isPercent === true, each key and value is a supportType
        -  when isPercent === false, each key is a supportType + '_amount'. The value is a supportType

      Parameters :
        supportTypes : array of supportTypes
        isPercent : boolean, true when displaying percent, false when displaying amount 
        
      Returns :
        keyTexts : object with keys and values as described above

          isPercent = true
              keyTexts = {
                  Bonds : "Bonds",
                  Emerging Markets : "Emerging Markets",
                  Europe : "Europe",
                  French : "French",
                  ...
              }
          isPercent = false
              keyTexts = {
                  Bonds_amount : "Bonds",
                  Emerging Markets_amount : "Emerging Markets",
                  Europe_amount : "Europe",
                  French_amount : "French",
                  ...
              }
    */
    function createKeyTexts(supportTypes, isPercent) {
        const keyTexts = {}
        console.log('assetAlloc - supportTypes:', supportTypes);

        supportTypes.forEach(key => {
          keyTexts[ isPercent ? key : key + '_amount' ] = key
        })

        // override the keys that we want to display differently
        keyTexts.taxYear = 'Year'
        return keyTexts
    }

    /*  ----------------------------------------------------------------------------------
        useMemo : Create graph and table data from simulationData
    */
    const { tableData, graphData, supportTypes} = useMemo(() => {
        const numKeys = Object.keys(simulationData).length
        if (numKeys === 0) {
            const tableData = []
            const graphData = []
            return {tableData, graphData}
        }

        console.log('assetAlloc - simulationData["assetAlloc"]:', simulationData["assetAlloc"]);
        
        // get max year as numercial value
        const maxYear = parseInt(simulationData['resultSummary']['maxYear'])

        const tableData = [];
        const graphData = [];
        const assetAllocData = simulationData["assetAlloc"]

        /* 
        graphData is an array of objects with the following structure
        =============================================================
        (same as assetAllocData, but additional _amount fields added)
          graphData :  [{
              "taxYear": 2023,
              "ageStr" : "61 / 53",
              "portfolioTotal" :  1582661,
              "Bonds" : 32.0791376043,
              "Bonds_amount" : 507704,
              "Emerging Markets" : 8.7762319284,
              "Emerging Markets_amount" : 138898,
              "Europe" : 14.0142456281,
              "Europe_amount" : 221798,
              ...        
            }, ...
          ]

        tableData is an array of objects with the following structure
        =============================================================
          tableData : [ {
              "taxYear": 2023,
              "ageStr" : "61 / 53",
              "support" : "Bonds",
              "percent" : 35.0791376043,
              "amount" : 520286
          }, ...]
        */

        const supportTypes = new Set();

        var id = 0
        assetAllocData.forEach( (yearData, yearIndex) => {
            const y = yearData['taxYear']
            const portfolioTotal = simulationData['cashflow'][yearIndex]['portfolioTotal']

            if ( y <= maxYear ) {
              // create tableData
              // =================
              Object.keys(yearData).forEach(key => {
                if (key !== 'taxYear' && key !== 'ageStr') {

                  tableData.push({
                      "taxYear": y,
                      "ageStr" : yearData['ageStr'],
                      "support" : key,
                      "percent" : yearData[key],
                      "amount" : Math.round(yearData[key] * portfolioTotal / 100),  // calculate amount from percent  ] ,
                      "id" : id
                  })
                  id++
                  supportTypes.add(key)
                }
              })

              // create graphData
              // =================              
              // add a new key with '_amount' with the portfolio value for each support
              var amountKeys = {}
              supportTypes.forEach(key => {
                // if a key  exists, add a corresponding '_amount' key
                if ( ( key in yearData) ) {
                  // if key does not end in '_amount' and does not start with 'portfolioTotal', add a corresponding '_amount' key
                  if ( !key.endsWith('_amount') && !key.startsWith('portfolioTotal') ) {
                    amountKeys[key + '_amount'] = Math.round(yearData[key] * portfolioTotal / 100)
                  } 
                }
              })
              // add yeadData (from assetAllocData) and amountKeys (calculated above) to graphData array
              graphData.push(
                { ...yearData, 
                  ...amountKeys,
                  'portfolioTotal': portfolioTotal
                }
                )
              
               
            }                
          })

        console.log('assetAlloc - supportTypes:', supportTypes);

        console.log('assetAlloc - graphData:', graphData);
        console.log('assetAlloc - tableData:', tableData);

        return { tableData, graphData, supportTypes }
    }, [simulationData]);


    /*  ----------------------------------------------------------------------------------
        useMemo : Create keyTexts object 
                  It  is used to control which data to display on the graph 
                  The keyTexts object changes contents depending on whether 
                  we are displaying percent or amount
    */
    const keyTexts = useMemo(() => {
      const numKeys = Object.keys(simulationData).length
      if (numKeys === 0) {
          return []
      }

      const keyTexts = createKeyTexts(supportTypes, isPercent)

      console.log('assetAlloc - keyTexts:', keyTexts);
      return keyTexts
    }, [simulationData, supportTypes, isPercent]);


  const handleSwitchChange = () => {
    setIsPercent(!isPercent);
    console.log('assetAlloc - isPercent:', isPercent ? 'Percent' : 'Amount');
  };


  console.log('assetAlloc - data:', graphData);

  return (
    <div >
      <Grid container spacing={1}>
      <ResultHeading 
            title="Répartition des actifs" 
            text="" 
            textBefore="Portefeuille"
            navigateBefore="/portfolio"
            navigateNext="/tax-summary"
            textNext="Fiscalité"
      >
            <Grid item xs={2}/>
            {/* Percent / Amount switch */}
            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="p" paddingRight={2} style={{ display: 'inline-block' }}>Montant</Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={isPercent}
                    onChange={handleSwitchChange}
                    name="graphDataSwitch" />
                }
              />
              <Typography variant="p" style={{ display: 'inline-block' }} paddingLeft={0} align="left">%</Typography>
            </Grid>
          </ResultHeading>

        {/* if graphData not defined display page with test  "No simulation has been performed" and display link to "inputs" route */}
        { graphData && graphData.length === 0 && (
          <Grid item xs={12}>
            <NoSimulationData />
          </Grid>
        )}

        {graphData && graphData.length > 0 && expandedComponent !== 'Table' && (
          <Grid item xs={expandedComponent === 'Graph' ? 12 : 6} md={expandedComponent === 'Graph' ? 12 : 6} style={{ border: '1px solid black' }}>
            <GraphTableExpandButton handleExpand={handleExpand} handleReduce={handleReduce} expandedComponent={expandedComponent} componentName='Graph' />
            <AssetAllocGraph graphData={graphData} keyTexts = {keyTexts}/>
          </Grid>
        )}        

        {graphData && graphData.length > 0 && expandedComponent !== 'Graph' && (
          <Grid item xs={expandedComponent === 'Table' ? 12 : 6} md={expandedComponent === 'Table' ? 12 : 6} style={{ border: '1px solid black', justifyContent: 'flex-end', overflow: 'hidden' }}>
            <GraphTableExpandButton handleExpand={handleExpand} handleReduce={handleReduce} expandedComponent={expandedComponent} componentName='Table' />
            <AssetAllocTable tableData={tableData} keyTexts = {keyTexts} />
          </Grid>
        )}

      </Grid>
    </div>
  );
}

export default AssetAlloc;