import React, { useContext } from "react";
import InputWithGrid from "./inputWithGrid.component";
import { useTraceUpdate } from '../../utils/useTraceUpdate.utils';

import { InputDatesContext } from "../../contexts/inputDates.context";
import {
  initialDateForm,
  dateCols,
} from "../../common/inputGridColumns.constants";


function InputDates() {
  const _parent = "InputDates";
  useTraceUpdate({ _parent: _parent});

  const {
    dateFormData,
    setDateFormData,
    dateList,
    setDateList,
    dateErrors,
    setDateErrors,
  } = useContext(InputDatesContext);

  return (
          <InputWithGrid
            inputName="Dates"
            key="dates"
            inputCols={dateCols}
            formData={dateFormData}
            setFormData={setDateFormData}
            dataList={dateList}
            setDataList={setDateList}
            errors={dateErrors}
            setErrors={setDateErrors}
            initialFormData={initialDateForm}
            _parent={_parent}
          />
  );
}

export default InputDates;
