
import { createContext, useContext, useEffect, useMemo, useState } from "react";

import { initialPeopleForm,} from "../common/inputGridColumns.constants";
import { DropDownListContext } from "./dropDownList.context";

// year : is used to synchronise the year on the chart with the table data year
// chart : does not appear to be used

export const InputPeopleContext = createContext({
  peopleFormData: initialPeopleForm,
  setPeopleFormData: () => {},
  peopleList: [],
  setPeopleList: () => {},
  peopleErrors: {},
  setPeopleErrors: () => {},
  initialPeopleForm: initialPeopleForm,
});

export const InputPeopleProvider = ({ children }) => {

  // import personDropDownList from DropDownListProvider
  // ==================================================
  const { setPersonDropDownList } = useContext(DropDownListContext);


  // person data
  // ============
  const [peopleFormData, setPeopleFormData] = useState(initialPeopleForm);
  const [peopleList, setPeopleList] = useState([]);
  const [peopleErrors, setPeopleErrors] = useState({});


    // create list of people for use in dropdown list
    useEffect(() => {
      // update list of people
      // =====================
      let nameList = [];
      const cnt = peopleList.length;
      if (cnt >= 0) {
        // create a list of sorted names from the list of people in peopleList
        // ===================================================================
        // get a list of names from peopleList (ignore empty names)
        nameList = peopleList.map((person) => person.name).filter((name) => name !== "");
        // sort the list of names
        nameList.sort();

        // make a new array of all permutations of the names in nameList, concatenated with ";"
        // this is used to create a list of all possible combinations of people
        // e.g. ["Lars", "Juju", "Soda", "Lars;Juju", "Lars;Soda", "Juju;Soda", "Lars;Juju;Soda"]
        const permutations = [];
    
        // Iterate over all possible combinations of the names.
        for (let i = 0; i < 1 << nameList.length; i++) {
          // Create a new permutation.
          const permutation = [];
    
          // For each bit in the combination, add the corresponding name to the permutation.
          for (let j = 0; j < nameList.length; j++) {
            if ((i >> j) & 1) {
              permutation.push(nameList[j]);
            }}
    
          // Add the permutation to the array (if it is not empty)
          if (permutation.length > 0) {
            permutations.push(permutation.join(";"));
          }
        }
    
        // sort the list by the number of ";" in each element, then alphabetically
        permutations.sort((a, b) => {
          return a.split(";").length - b.split(";").length || a.localeCompare(b);
        });
        
        console.log("personDropDownList", permutations);
        setPersonDropDownList(permutations);
      }
    }, [peopleList]);
  
  
  const value = useMemo(() => ({
    peopleFormData,
    setPeopleFormData,
    peopleList,
    setPeopleList,
    peopleErrors,
    setPeopleErrors,
  }), [peopleFormData, peopleList, peopleErrors]);

  return (
    <InputPeopleContext.Provider value={value}>
      {children}
    </InputPeopleContext.Provider>
  );
};
