import { Typography } from '@mui/material'; 

function NoSimulationData() {
    return (
        <>
            <Typography variant="body1">
            Aucune simulation n'a été effectuée.
            </Typography>
            <Typography variant="body1">
            Veuillez effectuer une simulation en :
            </Typography>
            <Typography variant="body1">
            - saisissant des données manuellement sur la page <a href="/inputs">Données</a>, ou
            </Typography>
            <Typography variant="body1">
            - important un fichier sur la page  <a href="/import">Importer</a>
            </Typography>
        </>
    );
}

export default NoSimulationData;
