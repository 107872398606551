import React, {useState} from "react";
import { Typography, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions  } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import InfoIconDialog from "../components/InfoIconDialog.component";

export function GridHeading  ({ heading }) {


  const [open, setOpen] = useState(false);
  const handleClickOpen = (event) => {
    event.stopPropagation(); // This stops the event from propagating further
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const translations = {
    "parameters" : "paramètres",
    "people" : "personnes",
    "dates" : "dates",
    "revenues" : "revenus",
    "expenses" : "dépenses",
    "inputPortfolio" : "portefeuille",
    "inputParameters" : "paramètres",
    // Add more translations as needed
  };
  console.log( "GridHeading - translations : ", heading, " - ", translations[heading])

  const infoText = "Here is some important information about " + translations[heading] || heading
  console.log( "GridHeading - infoText : ", infoText)

  return (
    <div style={{ display: 'flex', alignItems: 'left' }}>
      <Typography variant="body1" style={{  fontWeight: 'bold', fontSize: '16px'}}>
        {translations[heading] || heading}
      </Typography>
      <InfoIconDialog heading={heading} >
        <Typography variant="body">
          {infoText}
        </Typography>
      </InfoIconDialog>      
    </div>
  );
}

/*
      <IconButton onClick={handleClickOpen} style={{ color: 'blue', marginLeft: '30px', marginTop: '-5px' }}>
        <InfoIcon />
      </IconButton>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{translations[heading] || heading}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography variant="body">
                Here is some important information about "{translations[heading] || heading}".
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

*/

export function PageHeading ({ heading }) {

  return (
    <Typography variant="h5" style={{ fontWeight: 'bold' }}>
      {console.log(heading)}
      {heading}
    </Typography>
  );
}
