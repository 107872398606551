import { createContext, useMemo, useState} from 'react';


// year : is used to synchronise the year on the chart with the table data year
// chart : does not appear to be used

export const ChartDisplayContext = createContext({
    year: 0,
    setNewYear: () => {},
    chart: '', 
    setChart: () => {}
});

export const ChartDisplayProvider = ({ children }) => {
    const [year, setYear] = useState(-1);
    const [chart, setChart] = useState('');

    const setNewYear = (selectedYear) => {
        setYear(selectedYear);
    };
    
    const value = useMemo(() => ({
        year,
        setNewYear,
        chart,
        setChart
    }), [year, chart]);

    return (
        <ChartDisplayContext.Provider value={value}>
        {children}
        </ChartDisplayContext.Provider>
    );
};
  

