import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";
import { GridHeading } from "../../styles/heading.styles";

import { ImportDataGridColumns } from "../../common/inputGridColumns.constants";


export function ImportDataGrid({ sheet, data, tight=false, indent=0 }) {
  // console.log("ImportDataGrid sheet(", sheet, ") data:", data);
  const theme = useTheme();

  const getRowClassName = (params) => {
    if (!params.row._valid) {
      return "invalid-row";
    }
    if (params.row._errMessages === "IMPORTÉ") {
      return "imported-row";
    }
    return "";
  };

  const invalidRowStyle = {
    backgroundColor: "salmon",
  };

  const leftColumns = [
    {
      field: "id",
      headerName: "Ligne",
      // flex: 0.3,
      width: 50,  // #16 : import - page layout
      headerClassName: "header-class",
      sortable: false, // Disables sorting when clicking on the header
      renderHeader: (params) => (
        <span title="Ligne">Ligne</span> // Tooltip
      ),  
    },
  ];

  const rightColumns = [
/*
    {
      field: "_valid",
      headerName: "Valid",
      flex: 0.5,
      headerClassName: "header-class",  
    },
    */
    {
      field: "_errFields",
      headerName: "Champs",
      flex: 0.5,
      headerClassName: "header-class",
    },
    {
      field: "_errMessages",
      headerName: "Description",
      flex: 0.8,
      headerClassName: "header-class",
    },
  ];

  const dataCols = ImportDataGridColumns[sheet];
  // console.log("ImportDataGrid sheet(",sheet, ") ImportDataGridColumns:", ImportDataGridColumns);
  if (!dataCols) {
    console.log("ImportDataGrid sheet(",sheet, ") dataCols:", dataCols);
    return <></>;
  }
  const finalCols = [...leftColumns, ...dataCols, ...rightColumns];

  console.log("ImportDataGrid sheet(", sheet, ") data:", data, "finalCols:", finalCols);

  return (
    <>
      {tight ? (
        <></>
      ) : (
      <Box sx={{ paddingTop: '10px'}}>
        <GridHeading heading = {sheet} />          
      </Box>
      )}

      <div style={{ paddingTop: `${indent * 10}px`, paddingLeft: `${indent * 100}px` }}>
      <DataGrid
        rows={data}
        columns={finalCols}
        pageSize={10}
        rowsPerPageOptions={[5, 10, 20]}
        scrollbarSize={10}
        hideFooterRowCount
        disableColumnResize
        disableColumnMenu
        columnHeaderHeight={20}
        rowHeight={24}
        hideFooter={true}
        getRowClassName={getRowClassName}
        sx={{
          "& .MuiDataGrid-cell": {
            fontSize: '0.75rem', // Adjust cell font size
          },
          "& .header-class": {
            fontSize: '0.75rem',  /* Adjust the header font size */
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
          "& .invalid-row": {
            backgroundColor: "salmon", // Set the background color for invalid rows
            "&:hover": {
              backgroundColor: "salmon", // Set the same color on hover to keep it consistent
            },
          },
          "& .imported-row": {
            backgroundColor: "lightgray", // Set the background color for imported rows
            "&:hover": {
              backgroundColor: "lightgray", // Set the same color on hover to keep it consistent
            },
          },
        }}
      />
      </div>
    </>
  );
}
