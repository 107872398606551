import { Box, Grid, Typography } from '@mui/material'; 
import { Link } from 'react-router-dom';
import { PageHeading } from '../styles/heading.styles';

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';


function ResultHeading ({ title, text, textBefore, navigateBefore, textNext, navigateNext, children }) {

    return (
        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', minHeight: '50px' }}>
        <PageHeading heading={title}/>
        <Typography variant="body" style={{ display: 'inline-block', paddingLeft: '1em' }}>
            {text}
        </Typography>
        {children}
        <Box display="flex" justifyContent="flex-end" alignItems="center" flexGrow={1}>
            { navigateBefore && (
                <Link to={navigateBefore}>
                    <NavigateBeforeIcon />
                </Link>
            )}
            { textBefore && (
                <Link to={navigateBefore}>
                  <Typography variant="body2" style={{  paddingRight:'1em'}}>
                    {textBefore}
                  </Typography>
                </Link>
            )}
            { textNext && (
                <Link to={navigateNext}>
                  <Typography variant="body2" style={{ paddingLeft:'1em'}}>
                    {textNext}
                  </Typography>
                </Link>
            )}
            { navigateNext && (
                <Link to={navigateNext}>
                    <NavigateNextIcon />
                </Link>
            
            )}
        </Box>
        </Grid>
    );

}

export default ResultHeading;



/*
<Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
<PageHeading heading="Flux de trésorerie"/>
<Typography variant="body" style={{ display: 'inline-block', paddingLeft: '1em' }}>
  Revenus net d'impôts, déductions de portefeuille brut (avant impôts). 
</Typography>
<Box display="flex" justifyContent="flex-end" flexGrow={1}>
  <Link to="/overview">
    <NavigateBeforeIcon />
  </Link>
  <Link to="/withdrawal-rates">
    <NavigateNextIcon />
  </Link>
</Box>
</Grid>
*/

