import React, { useContext } from "react";
import { Typography, useTheme } from "@mui/material";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../styles/accordion.styles";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// import InputPortfolio from "../components/input/inputPortfolio.component";
import InputPeople from "../components/input/inputPeople.component";
import InputDates from "../components/input/inputDates.component";
import InputRevenue from "../components/input/inputRevenue.component";
import InputExpense from "../components/input/inputExpense.component";
import InputParameters from "../components/input/inputParameters.component";
import { GridHeading, PageHeading } from "../styles/heading.styles";

import InputButtons from "../components/inputButtons.component";

import { InputDataContext } from "../contexts/inputData.context";
import { DropDownListContext } from "../contexts/dropDownList.context";
import {
  supportList,
  initialParameters,
} from "../common/inputGridColumns.constants";

import { useTraceUpdate } from '../utils/useTraceUpdate.utils';

function Inputs() {
  useTraceUpdate({ _parent: "Inputs"});

  const InputPortfolio = React.lazy(() =>
    import("../components/input/inputPortfolio.component")
  );
  // Do this for all your Input* components

  const theme = useTheme();

  const {
    // accordion state
    expanded,
    setExpanded,
  } = useContext(InputDataContext);


  const handleCollapseAll = () => {
    setExpanded([]);
  };

  const handleExpandAll = () => {
    setExpanded([
      "people",
      "dates",
      "revenues",
      "expenses",
      "inputPortfolio",
      "inputParameters",
    ]);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      setExpanded([...expanded, panel]);
    } else {
      setExpanded(expanded.filter((p) => p !== panel));
    }
  };

  const CustomAccordion = ({ name, InputComponent, useSuspense = false }) => (
    <Accordion
      expanded={expanded.includes(name)}
      onChange={handleChange(name)}
      panel={name}
    >
      <AccordionSummary 
        aria-controls="panel1-content" 
        id="panel1-header"
        expandIcon={<ExpandMoreIcon />}
        sx={{ paddingLeft: '10px', height: '0px'}}        
        >
        <GridHeading heading = {name} />                    
      </AccordionSummary>
      <AccordionDetails>
        {useSuspense ? (
          <React.Suspense fallback={<div>Chargement en cours...</div>}>
            <InputComponent />
          </React.Suspense>
        ) : (
          <InputComponent />
        )}
      </AccordionDetails>
    </Accordion>
  );


  return (
    <div style={{ marginBottom: '20px' }}>
      <PageHeading heading="Données"/>

      <InputButtons handleCollapseAll={handleCollapseAll} handleExpandAll={handleExpandAll} expanded={expanded} theme={theme} />

      <CustomAccordion name="people" InputComponent={InputPeople} />
      <CustomAccordion name="dates" InputComponent={InputDates} />
      <CustomAccordion name="revenues" InputComponent={InputRevenue} />
      <CustomAccordion name="expenses" InputComponent={InputExpense} />
      <CustomAccordion name="inputPortfolio" InputComponent={InputPortfolio} useSuspense={true} />
      <CustomAccordion name="inputParameters" InputComponent={InputParameters} />

      <InputButtons handleCollapseAll={handleCollapseAll} handleExpandAll={handleExpandAll} expanded={expanded} theme={theme} />

    </div>
  );
}

export default Inputs;
