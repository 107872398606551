// npm install react-router-dom@6
// npm install recharts
// npm install material-react-table @mui/material @mui/icons-material @emotion/react @emotion/styled


import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import { ChartDisplayProvider } from './contexts/chartDisplay.context'
import { SimulationDataProvider } from './contexts/simulationData.context'
import { InputDataProvider } from './contexts/inputData.context'
import { InputParametersProvider } from './contexts/inputParameters.context'
import { InputPeopleProvider } from './contexts/inputPeople.context'
import { InputDatesProvider } from './contexts/inputDates.context'
import { InputRevenueProvider } from './contexts/inputRevenue.context'
import { InputExpenseProvider } from './contexts/inputExpense.context'
import { InputPortfolioProvider } from './contexts/inputPortfolio.context'
import { RefLineYearsProvider } from './contexts/refLineYears.context'

import { DropDownListProvider } from './contexts/dropDownList.context'

import App from './App';

import reportWebVitals from './reportWebVitals';
/*
                      <RefLineYearsProvider>

*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SimulationDataProvider>
      <ChartDisplayProvider>
        <DropDownListProvider>
          <InputDataProvider>
            <InputParametersProvider>
              <InputPeopleProvider>
                <InputDatesProvider>
                  <InputRevenueProvider>
                    <InputExpenseProvider>
                      <InputPortfolioProvider>
                        <RefLineYearsProvider>
                          <App />
                        </RefLineYearsProvider>
                      </InputPortfolioProvider>
                    </InputExpenseProvider>
                  </InputRevenueProvider>
                </InputDatesProvider>
              </InputPeopleProvider>
            </InputParametersProvider>
          </InputDataProvider>
        </DropDownListProvider>
      </ChartDisplayProvider>
    </SimulationDataProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
