import React from 'react';
import Button from '@mui/material/Button';

function DownloadFile({ fileName }) {
    const handleDownload = async () => {
        const fileUrl = process.env.PUBLIC_URL + '/files/' + fileName;
        const response = await fetch(fileUrl);
        console.log('response:', response);
    
        if (!response.ok) {
          alert('Erreur : fichier introuvable');
          return;
        }
    
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    
    
  return (
    <Button 
      variant="contained" 
      color="primary" 
      onClick={handleDownload}
      style={{ height: '25px', minHeight: '25px' }}
      >
      Téléchargez
    </Button>
  );
}

export default DownloadFile;