/*  ----------------------------------------------------------------------------------
    Create dict to convert person name to shortest string possible

    Generated by GPT-4
   
    It sounds like you are looking for a way to generate a dictionary of abbreviations for a 
    list of names in JavaScript. One possible algorithm to do this is to use a trie data 
    structure to store the names and their prefixes, and then traverse the trie to find the 
    shortest unique prefix for each name. A trie is a tree-like structure where each node 
    represents a character and each path from the root to a leaf represents a word. You can 
    learn more about tries and how to implement them in JavaScript from this article.

    https://stackoverflow.com/questions/32527026/shortest-path-in-javascript
*/

// A helper function to create a new trie node
function TrieNode(char) {
  this.char = char; // The character stored in this node
  this.children = {}; // An object to store the children nodes
  this.isEnd = false; // A flag to indicate if this node is the end of a word
  this.count = 0; // A counter to keep track of how many words share this node
}

// A helper function to insert a word into a trie
function insert(word, root) {
  let node = root; // Start from the root node
  for (let i = 0; i < word.length; i++) {
    // Loop through each character of the word
    let char = word[i]; // Get the current character
    if (!node.children[char]) {
      // If the character is not in the children of the node
      node.children[char] = new TrieNode(char); // Create a new node for it
    }
    node = node.children[char]; // Move to the next node
    node.count++; // Increment the counter of the node
  }
  node.isEnd = true; // Mark the node as the end of the word
}

// A helper function to find the shortest unique prefix for a word in a trie
function findPrefix(word, root) {
  let node = root; // Start from the root node
  let prefix = ""; // Initialize an empty prefix
  for (let i = 0; i < word.length; i++) {
    // Loop through each character of the word
    let char = word[i]; // Get the current character
    prefix += char; // Append the character to the prefix
    node = node.children[char]; // Move to the next node
    if (node.count === 1) {
      // If the node is unique, meaning no other word shares this prefix
      return prefix; // Return the prefix as the shortest unique prefix
    }
  }
  return word; // If no unique prefix is found, return the whole word
}

// A function to generate a dictionary of abbreviations for a list of names
export function createPersonDict(names) {
  let dict = {}; // Initialize an empty dictionary
  let root = new TrieNode(""); // Create a root node for the trie
  for (let name of names) {
    // Loop through each name in the list
    insert(name, root); // Insert the name into the trie
  }
  for (let name of names) {
    // Loop through each name in the list again
    let prefix = findPrefix(name, root); // Find the shortest unique prefix for the name
    dict[name] = prefix; // Add the name and the prefix to the dictionary
  }
  return dict; // Return the dictionary
}

/* ------------- End og GPT-4 genereated code -------------



/* Preprocess data

    This function is called from simulate.component.jsx after the simulation data is received from the backend.
    It is used to create the data that will be used to display the graphs and tables.
    It is also used to create the data that will be used to display the cashflow page.


*/
export const preProcessData = (data) => {
  console.log("data['people']", data["people"]);

  /* Create a dictionary to convert person name to shortest string possible
    ============================================================================ */
  // creaet a list of person names from the people data
  const persons = [];
  data["people"].forEach((person) => {
    persons.push(person["person"]);
  });

  console.log("data['people']", data["people"]);
  console.log("persons", persons);
  const dict = createPersonDict(persons);
  console.log("Shortest people names dict", dict);
  data["personNameDict"] = dict;

  // convert dates to strings
  data["people"].forEach((person) => {
    // remove time from birthYear and lifeSpanDate
    // person['birthDate'] is a string in the format 'YYYY-MM-DDTHH:MM:SSZ'
    person["birthDate"] = person["birthDate"].substring(0, 10);
    person["birthYear"] = person["birthDate"].substring(0, 4);
    person["lifespanDate"] = person["lifespanDate"].substring(0, 10);
  });


  /* Create ageStrArray and add ageStr to each data type that contains a taxYear
    ============================================================================ */
  var ageStrArray = [];
  const minYear = data["cashflow"][0]["taxYear"];

  // create a new entry in resultSummary for minYear
  data["resultSummary"]["minYear"] = minYear;

  console.log("minYear :", minYear);
  // using the taxYear data from the cashflow type (because there is one row per year), create an ageStr array, then add it to each data type that contains a taxYear
  data["cashflow"].forEach((yearData, index) => {
    const y = yearData["taxYear"];

    // add ages of each person for every year
    var ageStr = "";
    data["people"].forEach((person) => {

      if (y - person["birthYear"] <= person["lifespanAge"]) {
        if (ageStr !== "") {
          ageStr += " / ";
        }
        ageStr += y - person["birthYear"];
      }
      // console.log("taxYear", y,  "ageStr :", ageStr);
    });

    ageStrArray[y - minYear] = ageStr;
  });

  // for each type that contains a taxYear, add the agrStr from ageStrArray
  const yearBasedData = [
    "assetAlloc",
    "cashflow",
    "expense",
    "ptfSummary",
    "revenue",
    "taxSummary",
  ];
  yearBasedData.forEach((type) => {
    data[type].forEach((yearData, index) => {
      const y = yearData["taxYear"];
      // add the ageStr to the data. Since yearData is a reference to the data[type] array, this will add the ageStr to the data[type] array
      yearData["ageStr"] = ageStrArray[y - minYear];
    });
  });

  // add ageStrArray to data
  data["ageStrArray"] = ageStrArray;

  /* Create list of portfolio supports
    ================================== */
  const assetAllocData = data["assetAlloc"];
  const supportTypes = new Set();

  // Iterate through each dictionary in the array
  for (const dict of assetAllocData) {
    // Iterate through the keys in the current dictionary
    for (const key in dict) {
      // Add the key to the uniqueKeys Set
      supportTypes.add(key);
    }
  }

  supportTypes.delete("taxYear");
  supportTypes.delete("ageStr");

  data["supportTypes"] = supportTypes;

  console.log("data :", data);
};
