import React from 'react';
import { Grid, Typography} from '@mui/material'; 
import ResultHeading from '../components/resultHeading.component';

function CrashScenario() {
  return (
  <div>
    <Grid container spacing={1}>
    <ResultHeading 
        title="Scénario de krach" 
        text="" 
        navigateBefore="/tax-summary"
        navigateNext="/podcasts"
        />

  </Grid>
    <Typography variant="body1">
      Rejouer les krachs précédents (Grande Dépression / Inflation des années 60 / Bulle internet / Grande crise financière)
    </Typography>
    <Typography variant="body1">
      Vérifier la durée des fonds d'urgence
    </Typography>
    <Typography variant="body1">
      Vérifier la durée du portefeuille en cas de krach à 80%
    </Typography>
    <Typography variant="body1">
      Vérifier la durée du portefeuille en épuisant d'abord les liquidités puis le portefeuille avec une chute de 80%
    </Typography>
    
    </div>
  );
}

export default CrashScenario;