import { createContext, useContext, useEffect, useMemo, useState } from "react";

import { initialDateForm, initialDates } from "../common/inputGridColumns.constants";
import { DropDownListContext } from "./dropDownList.context";

// year : is used to synchronise the year on the chart with the table data year
// chart : does not appear to be used

export const InputDatesContext = createContext({
  dateFormData: initialDateForm,
  setDateFormData: () => {},
  dateList: [],
  setDateList: () => {},
  dateErrors: {},
  setDateErrors: () => {},
  initialDateForm: initialDateForm,
  initialDates: initialDates,
});

export const InputDatesProvider = ({ children }) => {
  // import personDropDownList from DropDownListProvider
  // ==================================================
  const { setPredefinedDates } = useContext(DropDownListContext);

  // date data
  // ============
  const [dateFormData, setDateFormData] = useState(initialDateForm);
  const [dateList, setDateList] = useState(initialDates);
  const [dateErrors, setDateErrors] = useState({});
  
  // create list of dates for use in dropdown list
  useEffect(() => {
    console.log("predefinedDates", dateList);
    if (dateList.length >= 0) {
      let list = dateList.map((row) => row.name);
//    list.push( "Now");
      //    list.push( "Never");

      // sort the list case insensitive. "now" and "never" should be at the top
      list.sort((a, b) => {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });

      setPredefinedDates(list);
      console.log("predefinedDates", list);
    }
  }, [dateList]);

  const value = useMemo(() => ({
    dateFormData,
    setDateFormData,
    dateList,
    setDateList,
    dateErrors,
    setDateErrors,
    initialDates,
  }), [dateFormData, dateList, dateErrors]);

  return (
    <InputDatesContext.Provider value={value}>
      {children}
    </InputDatesContext.Provider>
  );
};
