import React, { useContext, useMemo } from "react";
import * as yup from "yup";
import { InputDataContext } from "../contexts/inputData.context";

// import useDateValidation from "../customHooks/useDateValidation.hooks";

// console.log("inputValidation.constants.jsx - useDateValidation:", useDateValidation);

// console.log("inputValidation.constants.jsx - useDateValidation():", useDateValidation() );
// console.log("inputValidation.constants.jsx - validateDateOrPredefined:", validateDateOrPredefined);

export const repeatPeriodList = ["monthly", "yearly", "oneTime"];

export const revenueSchema = yup.object().shape({
    name: yup.string().required(),
    amount: yup.number().positive().required(),
    repeatPeriod: yup.string().oneOf(repeatPeriodList).required(),
    repeatFreq: yup.number().positive().integer().required(),
    startDate: yup.mixed().test('dateOrPredefined', 'Invalid value', function (value) {
        console.log("revenueSchema - value(", value, ") type(", typeof value,")");
        // const { dateList } = this.parent;
        // const { dateList } = useContext(InputDataContext);
        const { dateList } = this.parent.context.options.context;
        console.log("revenueSchema - dateList(", dateList, ")");
        if (dateList["label"].includes(value)) {
          return true;
        }
        return false;
      }),
/*
    startDate: yup.mixed().test('dateOrPredefined', 'Invalid value', function (value) {
        const validateDateOrPredefined = useDateValidation();
        return validateDateOrPredefined(value);
      }), 
      */

    //    endDate: yup.mixed().test('dateOrPredefined', 'Invalid value', useDateValidation),
//    startDate: yup.date().required(),
//    endDate: yup.date().optional(),

/*
    startDate: yup
    .mixed()
    .test(
      'is-valid-date',
      'Start date must be a valid date',
      (value) => value instanceof Date && !isNaN(value)
    )
    .required(),
  endDate: yup
    .mixed()
    .test(
      'is-valid-date',
      'End date must be a valid date',
      (value) => value instanceof Date && !isNaN(value)
    )
    .test(
      'is-after-start-date',
      'End date must be after start date',
      function (value) {
        const startDate = this.parent.startDate;
        return !startDate || !value || value > startDate;
      }
    ),
*/
});
const expensesSchema = yup.object().shape({
    name: yup.string().required(),
    amount: yup.number().positive().required(),
    person: yup.string().required(),
    repeatPeriod: yup.string().oneOf(repeatPeriodList).required(),
    repeatFreq: yup.number().positive().integer().required(),
//    startDate: yup.mixed().test('dateOrPredefined', 'Invalid value', useDateValidation),
//    endDate: yup.mixed().test('dateOrPredefined', 'Invalid value', useDateValidation),
});

const portfolioSchema = yup.object().shape({
    name: yup.string().required(),
    person: yup.string().required(),
    initialCapital: yup.number().positive().required(),
    taxScheme: yup.string().required(),
    earliestWithdrawalDate: yup.date().required(),
    withdrawalPriority: yup.number().positive().required(),
    support: yup.string().required(),
    cashEquiv: yup.number().positive().required(),
    currentValue: yup.number().positive().required(),
    });

const taxSchemeSchema = yup.object().shape({
    name: yup.string().required(),
    category: yup.string().required(),
    _howPaid: yup.string().required(),
    adjustPerYear: yup.string().required(),
    ref: yup.number().positive().required(),
    percentTaxed: yup.number().positive().required(),
    useTaxScheme: yup.string().optional(),
    taxAmount: yup.number().positive().optional(),
    taxPercent: yup.number().positive().optional(),
    fromAmount: yup.number().positive().optional(),
    toAmount: yup.number().positive().optional(),
    appliesTo: yup.string().oneOf(["allWithdrawn", "capitalWithdrawn", "gainWithdrawn", "totalAmount"]).required(),
    comment: yup.string().required(),
});

const peopleSchema = yup.object().shape({
    name: yup.string().required(),
    birthDate: yup.date().required(),
    lifespanAge: yup.number().positive().required(),
    retireDate: yup.date().optional(),
});

const adjustPerYearSchema = yup.object().shape({
    name: yup.string().required(),
    amount: yup.number().positive().required(),
    period: yup.string().oneOf(["monthly", "quarterly", "yearly"]).required(),
    frequency: yup.number().positive().integer().required(),
    startDate: yup.date().required(),
    endDate: yup.date().min(yup.ref("startDate")).required(),
});

const dateSchema = yup.object().shape({
    name: yup.string().required(),
    date: yup.date().required(),
    comment: yup.string().optional(),
});

const parameterSchema = yup.object().shape({
    name: yup.string().required(),
    value: yup.string().required(),
});


export const importValidationSchemas = {
    "revenues": {
        validateSchema: revenueSchema
    },
    "expenses": {
        validateSchema: expensesSchema
    }, 
    "dates": {
        validateSchema: dateSchema
    }, 
    "parameters": {
        validateSchema: parameterSchema
    }, 
    "tax_schemes": {
        validateSchema: taxSchemeSchema
    }, 
    "people": {
        validateSchema: peopleSchema
    }, 
    "portfolio": {
        validateSchema: portfolioSchema
    },
    "adjustPerYear": {
        validateSchema: adjustPerYearSchema
    }
};


