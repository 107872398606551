import React  from 'react';
import { useTheme } from '@mui/material';
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from '@mui/material/Box';

import OverviewTable from './overviewTable.component'

export default function OverviewWithdrawalRates(props) {
    // cannot pass the expanded state from the parent component 
    // because the Accordion component is not a controlled component
    const { expanded, onChange, panel, displayData } = props;

    const theme = useTheme();
    
    return (
    <Accordion expanded={expanded} onChange={onChange}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
        >
            <Typography sx={{ width: "33%", flexShrink: 0, color: theme.palette.primary.dark }}>
                Taux de retrait
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Box sx={{ display: 'flex', alignItems: 'center' }}  paddingLeft={2} paddingBottom={4}>
                <OverviewTable displayData={ displayData.filter((row) => row.panel === panel)} />
            </Box>
        </AccordionDetails>

     </Accordion>
    );
}

