/*
React Pro sidebar
  https://www.npmjs.com/package/react-pro-sidebar
Example project
  https://codesandbox.io/s/react-dashboard-pnm6fh?file=/src/pages/global/sidebar/MyProSidebar.jsx:133-150
*/


import React from 'react';
import { AppBar, Box,  Toolbar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from '@mui/material/styles/styled';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import './Navbar.css';

const StyledButton = styled(Button)({
  color: 'white',
  height: '20px', // Adjust as needed
  padding: '0', // Adjust as needed
  minHeight: '0', // Adjust as needed
});
  
const StyledMenu = styled(Menu)({
'& .MuiPaper-root': {
    backgroundColor: 'white',
  },
});

const StyledMenuItem = styled(MenuItem)({
'& .MuiTypography-root': {
    color: 'white',
    height: '20px', // Adjust as needed
    padding: '0', // Adjust as needed
    minHeight: '0', // Adjust as needed
  },
}); 


function Navbar() {
    console.log('Navbar rendered');
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        console.log('Navbar handleClick()');
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
          <AppBar position="static" sx={{ minHeight: '40px', maxHeight: '40px', padding: '0', margin: '0' }}>
            <Toolbar>
            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, padding: '2px', marginBottom: '15px' }}>
              <Typography variant="h6"  >
              <Link to="/">
                <img src={process.env.PUBLIC_URL + '/FirecrackerSkyrocket small.png'} alt="Logo" style={{width: '30px', height: '30px'}}/>
              </Link>
              </Typography>
              <Typography variant="h5"  sx={{ fontWeight: 'bold', marginLeft: '10px' }}>
                FIRECRACKER
              </Typography>
              </Box>
              <StyledButton color="inherit" to="/getting-started" component={Link} style={{ marginRight: '10px', marginTop: '-10px' }}>
                  Commencer
              </StyledButton>
              <StyledButton color="inherit" to="/import" component={Link} style={{ marginRight: '10px', marginTop: '-10px' }}>
                  Importer
              </StyledButton>
              <StyledButton color="inherit" to="/inputs" component={Link} style={{ marginRight: '10px', marginTop: '-10px' }}>
                  Données
              </StyledButton>
              <StyledMenu
                id="results-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <StyledMenuItem onClick={handleClose} color="inherit" to="/overview" component={Link}>Résumé</StyledMenuItem>
                <StyledMenuItem onClick={handleClose} color="inherit" to="/cashflow" component={Link}>Trésorerie</StyledMenuItem>
                <StyledMenuItem onClick={handleClose} color="inherit" to="/withdrawal-rates" component={Link}>Taux de retrait</StyledMenuItem>
                <StyledMenuItem onClick={handleClose} color="inherit" to="/portfolio" component={Link}>Portefeuille</StyledMenuItem>
                <StyledMenuItem onClick={handleClose} color="inherit" to="/asset-alloc" component={Link}>Répartition des actifs</StyledMenuItem>
                <StyledMenuItem onClick={handleClose} color="inherit" to="/tax-summary" component={Link}>Fiscalité</StyledMenuItem>
                <StyledMenuItem onClick={handleClose} color="inherit" to="/podcasts" component={Link}>Liens</StyledMenuItem>
              </StyledMenu>
              <StyledButton
                color="inherit"
                aria-controls="results-menu"
                aria-haspopup="true"
                onClick={handleClick}                
                style={{ marginTop: '-10px'}}
              >
                Résultats
              </StyledButton>
            </Toolbar>
          </AppBar>
        </div>
      );
    }
    
    export default Navbar;