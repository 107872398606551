import React, { useCallback} from "react";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

function DropDownPresetsInput({
  label,
  name,
  value,
  onChange,
  errors,
  disabled = false,
  parentId,
  dropDownList,
}) {
  const handleChange = useCallback(
    (event) => {
      onChange(event, parentId);
    },
    [onChange, parentId]
  );

  return (
    <FormControl fullWidth>
      <InputLabel sx={{ top: "-35%" }}>{label}</InputLabel>
      <Select
        label={label}
        name={name}
        value={value}
        onChange={handleChange}
        sx={{ height: "2em" }}
        MenuProps={{ sx: { marginTop: "32px" } }}
        disabled={disabled}
        error={errors && !!errors[name]}
      >
        {dropDownList &&
          dropDownList.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

export { DropDownPresetsInput };
