import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';

const BadgeTypography = ({ text, badgeContent, color }) => (
    <Typography>
        <Badge 
            badgeContent={badgeContent} 
            color={color || 'error'}
            sx={{  
                marginRight: '15px',
                marginTop: '-5px',
                '.MuiBadge-badge': {
                    fontSize: '0.75em',
                    minWidth: '15px',
                    height: '15px',
                },
            }}
        />
        {text}
    </Typography>
);

export default BadgeTypography;