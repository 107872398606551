import React from "react";
import { useTheme } from '@mui/material';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import { useContext } from 'react';
import { ChartDisplayContext } from '../contexts/chartDisplay.context'
import renderTooltip  from '../utils/chartUtils';

import '../styles/graph.styles.css';

const PortfolioChart = ({ graphData, keyTexts }) => {

  const { setNewYear } = useContext(ChartDisplayContext);
  const theme = useTheme();

  
  console.log('portfolioChart - graphData:', graphData);
  // set year context on singleClick
  // https://github.com/recharts/recharts/issues/1980
  const yearClick = (event) => {
    if (event && event.activeLabel) {
      console.log('portfolio - set year: ', event.activeLabel)
      setNewYear(event.activeLabel)  
    }
  }

  // reset year context to -1 on doubleClick
  const doubleClick = (event) => {
    console.log('portfolio - double click')
    setNewYear(-1)
  }


  // tooltip displays 'year' in caption, then the values for the data point
  const paletteColors = Object.values(theme.palette);

  // create displayData if keyTexts is defined and has contents
  const displayData = keyTexts && Object.keys(keyTexts).length > 0 ? Object.keys(keyTexts).filter((key) => key !== 'taxYear' && key !== 'ageStr') : null;

  const displayTotal = true;

  return (
    <div onDoubleClick={doubleClick}>
    <ResponsiveContainer width="100%" aspect={16/9}>
      <ComposedChart
        data={graphData}
        className="no-select"
        syncId="ptfOverview"
        onClick={yearClick}

        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="taxYear" scale="band" style={{ fontSize: '12px' }}/>
        <YAxis tickFormatter={(value) => new Intl.NumberFormat('en-US').format(value)} style={{ fontSize: '12px' }}/>
        <Tooltip  content={(props) => renderTooltip(props, keyTexts, displayTotal)} /> 
        <Legend   
          formatter={(value) => { return keyTexts[value]; }}
          wrapperStyle={{ fontSize: '12px' }}
        />
        {displayData && displayData.map((key, index) => (
          <Bar 
            key={key} 
            dataKey={key} 
            stackId="a" 
            fill={paletteColors[(index+1) % paletteColors.length].main} // Use color from palette
          />
        ))}

      </ComposedChart>
      </ResponsiveContainer>
      </div>
  );
};

export default PortfolioChart;