import React from 'react';
import { Box, Button, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function GettingStarted() {
  const navigate = useNavigate();

  return (
    <div sx={{
      textAlign: 'center',
      padding: 4,
    }}>
      <Typography variant="h4" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
        Bienvenue sur Firecracker - le simulateur financier !
      </Typography>
      <Box sx={{ marginLeft: 2 }}>
      <Typography variant="body1" sx={{ marginBottom: 4 }}>
        Prêt à prendre le contrôle de votre avenir financier ? Notre simulateur vous permet de planifier, d'élaborer des stratégies et de visualiser votre parcours financier. Que vous soyez un investisseur chevronné ou que vous débutiez, nous sommes là pour vous.
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 4 }}>
        Que pouvez-vous faire avec notre simulateur ?
      </Typography>
      <ul>
        <li>
          Rendement des investissements : Découvrez comment vos investissements pourraient croître au fil du temps. Simulez différents scénarios, de conservateur à agressif, et voyez votre patrimoine grandir.
        </li>
        <li>
          Revenus et dépenses : Comprenez les mouvements de vos finances. Saisissez vos sources de revenus, suivez vos dépenses et obtenez des informations sur votre trésorerie.
        </li>
        <li>
          Aperçu fiscal français : C'est là où nous excellons ! Contrairement à d'autres simulateurs financiers gratuits, nous tenons compte de la fiscalité. Notre simulateur vous aide à optimiser vos impôts.
        </li>
        <li>
          Baisses d'investissement : La vie est pleine d'imprévus. Dépenses, urgences ou événements majeurs de la vie - notre simulateur vous aide à traverser les tempêtes. Planifiez les jours difficiles et gérez les contretemps liés aux investissements.
        </li>
      </ul>
      <Button variant="contained" color="primary" sx={{ marginTop: 2 }} onClick={() => navigate('/import')}>
        Explorer le simulateur
      </Button>
      
      <Typography variant="body1" sx={{ marginTop: 2 }}>
      Vous pouvez apporter une contribution libre pour ce service&nbsp;:&nbsp;
        <Link href="https://ko-fi.com/fire_cracker" target="_blank" rel="noopener noreferrer">
          Ko-fi
        </Link>
        {/* 
        &nbsp;or&nbsp;
        <Link href="https://www.buymeacoffee.com/firecracker" target="_blank" rel="noopener noreferrer">
          Buy Me a Coffee
        </Link>&nbsp;
      */}
      </Typography>
      <Box sx={{ marginTop: 10 }}>
        <Typography variant="caption">
          Avertissement : Ce simulateur fournit des estimations et des informations éducatives. Consultez toujours un conseiller financier pour obtenir des conseils personnalisés.
        </Typography>
      </Box>
      </Box>
    </div>

  );
};

export default GettingStarted;