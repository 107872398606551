import React, { useMemo, useContext, useState } from 'react';
import { Grid, Typography } from '@mui/material'; 
import GraphTableExpandButton from '../components/graphTableExpandButton.component';
import NoSimulationData from '../components/noSimulationData.component';
import ResultHeading from '../components/resultHeading.component';

import { SimulationDataContext } from '../contexts/simulationData.context'

import { PageHeading } from '../styles/heading.styles';

import WithdrawalRatesGraph from '../components/withdrawalRatesGraph.component';
import WithdrawalRatesTable from '../components/withdrawalRatesTable.component';
import { NoSim } from '@mui/icons-material';

function WithdrawalRates() {

  const { simulationData } = useContext(SimulationDataContext);
  const [expandedComponent, setExpandedComponent] = useState(null);

  const handleExpand = (componentName) => {
    setExpandedComponent(componentName);
  };

  const handleReduce = () => {
    setExpandedComponent(null);
  };

//  const [tableData, setTableData] = useState([]);   


  /* simulationData = {
        cashflow : [{                             Table               Graph
          "taxYear": 2023,                          X                   X
          "totalWithLuxuryNeg": -77648,             X                   
          "netIncome": 79894.0,
          "ptfMvt": 2021,
          "portfolioGainPercent": 3.8,
          "withdrawRate": 0.0,              X                   YL
          "cashEquivAmount": 502286.0               X                   

          cashEquivAmount / totalWithLuxuryNeg      X                   YR
        }, ...]
      }
*/

  const keyTexts = {
    "totalWithLuxury": "dépenses",
    "withdrawRate": "taux de retrait",
    "cashEquivAmount": "équivalent liquide",
    "safeYears": "Réserve (années)",
  }

  // const tableTypes = ["cashflow"]

  /*  ----------------------------------------------------------------------------------
      useMemo : Create graph and table data from simulationData
  */

  // load the tableData state with the selected dicts (in tableTypes) in the simulation data object
  //  [ {id: 1, year: y, ages: AgeStr, type: type,  name:key, amount:yearData[key] }, 
  //    {id: 2, year: y, ages: AgeStr, type: type,  name:key, amount:yearData[key] }, ....
  //  ]
  const {tableData, graphData} = useMemo(() => {

    // return if simulationData has not yet been populated
    const numKeys = Object.keys(simulationData).length
    if (numKeys === 0) {
      const tableData = []
      const graphData = []
      return {tableData, graphData}
    }

        
    // get max year as numercial value
    const maxYear = parseInt(simulationData['resultSummary']['maxYear'])

    const tableData = [];
    const safeYearsData = simulationData["safeYears"]

    // cut from here 
    // ----------------
    
    /*
    tableData is an array of objects with the following structure
    =============================================================
      tableData : [ {
          "ageStr" : "61 / 53",
          "taxYear": 2023,
          "safeYears" : 14.6,
          "cumulCashEquivAvail": 183935, // cashEquivAmount available at the end of the year
          "netWithdrawn": 4003,
          "brutWithdrawn": 4603,
          "portfolioTotal":124547, 
          "withdrawRate": 3.5,          
          "id" : 0
      }, ...]

    graphData
    =========
    (same as tableData)

*/

    var id = 0
    safeYearsData.forEach( (yearData) => {
      const {taxYear, ageStr, safeYears, cumulCashEquivAvail, 
             netWithdrawn, brutWithdrawn, portfolioTotal, totalWithLuxury, withdrawRate} = yearData

      if ( taxYear <= maxYear ) {

        // create tableData
        // ================
        // make safeYears to 2 decimal places
        tableData.push( {  
                      taxYear: taxYear, 
                      ageStr: ageStr, 
                      'safeYears': safeYears,
                      'cumulCashEquivAvail': cumulCashEquivAvail,
                      'netWithdrawn': netWithdrawn,
                      'brutWithdrawn': brutWithdrawn,
                      'portfolioTotal': portfolioTotal,
                      'totalWithLuxury': totalWithLuxury,
                      'withdrawRate': withdrawRate,                      
                      'id': id++ } )
      }
    });

    // ----------------


    // create graphData (in this case, same as tableData)
    const graphData = tableData;

    console.log('withdrawalRates - graphData', graphData);
    console.log('withdrawalRates - tableData:', tableData);        

    return {tableData, graphData};
  }, [simulationData]);


  return (
    <div >
      <Grid container spacing={1}>
      <ResultHeading 
          title="Taux de retrait" 
          text="Revenus net d'impôts, rachats bruts de portefeuille (avant impôts)." 
          textBefore="Trésorie"
          navigateBefore="/cashflow"
          textNext="Portefeuille"
          navigateNext="/portfolio"
      />

        {/* if graphData not defined display page with test  "No simulation has been performed" and display link to "inputs" route */}
        { console.log('withdrawalRates - graphData:', graphData)}
        { graphData && graphData.length === 0 && (
          <Grid item xs={12}>
            <NoSimulationData />
          </Grid>
        )}

        {graphData && graphData.length > 0 && expandedComponent !== 'Table' && (
          <Grid item xs={expandedComponent === 'Graph' ? 12 : 6} md={expandedComponent === 'Graph' ? 12 : 6} style={{ border: '1px solid black' }}>
            <GraphTableExpandButton handleExpand={handleExpand} handleReduce={handleReduce} expandedComponent={expandedComponent} componentName='Graph' />
            <WithdrawalRatesGraph graphData={graphData} keyTexts = {keyTexts}/>
          </Grid>
        )}        

        {graphData && graphData.length > 0 && expandedComponent !== 'Graph' && (
          <Grid item xs={expandedComponent === 'Table' ? 12 : 6} md={expandedComponent === 'Table' ? 12 : 6} style={{ border: '1px solid black', justifyContent: 'flex-end', overflow: 'hidden' }}>
            <GraphTableExpandButton handleExpand={handleExpand} handleReduce={handleReduce} expandedComponent={expandedComponent} componentName='Table' />
            <WithdrawalRatesTable tableData={tableData} />
          </Grid>
        )}

      </Grid>
    </div>
  );
}

export default WithdrawalRates;

/*
    
    const cashflowData = simulationData["cashflow"]

    console.log('WithdrawalRates - cashflowData:', cashflowData);
    console.log('WithdrawalRates - maxYear:', maxYear);

    / * 
        tableData is an array of objects with the following structure
        =============================================================
          tableData : [ {
              "ageStr" : "61 / 53",
              "taxYear": 2023,
              "safeYears" : 14.6,
              "cumulCashEquivAvail": 183935, // cashEquivAmount available at the end of the year
              "netWithdrawn": 4003
              "brutWithdrawn": 4603
              "withdrawRate" : 0,
              
              "safeYears" : 77.6,
              "withdrawRate" : 0,
              "totalWithLuxuryNeg" : -77648,
              "cashEquivAmount" : 502286,
              "id" : 0
          }, ...]

        graphData
        =========
        (same as tableData)

    * /

        var id = 0
        cashflowData.forEach( (yearData) => {
          const {taxYear, ageStr, totalWithLuxuryNeg, cashEquivAmount, withdrawRate} = yearData
    
          if ( taxYear <= maxYear ) {
    
            // create tableData
            // ================
            // make safeYears to 2 decimal places
            const safeYears = Math.round(0 - cashEquivAmount / totalWithLuxuryNeg * 12 * 10) / 10
            tableData.push( {  
                          taxYear: taxYear, 
                          ageStr: ageStr, 
                          'withdrawRate': withdrawRate,  
                          'totalWithLuxuryNeg': totalWithLuxuryNeg,  
                          'cashEquivAmount': cashEquivAmount,  
                          'safeYears': safeYears,
                          'id': id++ } )
            }
        });
    
*/    