import { useEffect, useRef } from 'react';

// debug hook to trace component updates
// props must contain a _parent property
// Example:
//       import { useTraceUpdate } from '../utils/useTraceUpdate.utils';
//       function funcName(props) {
//           useTraceUpdate({...props, _parent: "InputWithGrid"});
export function useTraceUpdate(props) {
  const prevProps = useRef(props);
  const { _parent} = props;
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((acc, [key, val]) => {
      if (prevProps.current[key] !== val) {
        acc[key] = [prevProps.current[key], val];
      }
      return acc;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log(_parent, ': Changed props:', changedProps);
    } else {
      console.log(_parent, ': No changed props');
    }
    prevProps.current = props;
  });
}
