import React, { useContext } from "react";
import InputWithGrid from "./inputWithGrid.component";

import {
    initialExpenseForm,
    expenseCols,
    taxSchemeList, 
    adjustPerYearList,   
} from "../../common/inputGridColumns.constants";
import { useTraceUpdate } from '../../utils/useTraceUpdate.utils';

import { InputExpenseContext } from "../../contexts/inputExpense.context";
import { DropDownListContext } from "../../contexts/dropDownList.context";

function InputExpense() {
    const _parent = "InputExpense";
    useTraceUpdate({ _parent: _parent});

    const {
        expenseFormData,
        setExpenseFormData,
        expenseList,
        setExpenseList,
        expenseErrors,
        setExpenseErrors,
    } = useContext(InputExpenseContext);

    const {
        predefinedDates,
        personDropDownList,
    } = useContext(DropDownListContext);

  return (
    <InputWithGrid
        inputName="Dépenses"
        key="expenses"
        inputCols={expenseCols}
        formData={expenseFormData}
        setFormData={setExpenseFormData}
        dataList={expenseList}
        setDataList={setExpenseList}
        errors={expenseErrors}
        setErrors={setExpenseErrors}
        initialFormData={initialExpenseForm}
        predefinedDates={predefinedDates}
        personDropDownList={personDropDownList}
        adjustPerYearList={adjustPerYearList}
        taxSchemeList={taxSchemeList}
        _parent={_parent}
    />
  );
}

export default InputExpense;
