import { createContext, useMemo, useState } from "react";

import { initialRevenueForm } from "../common/inputGridColumns.constants";

// year : is used to synchronise the year on the chart with the table data year
// chart : does not appear to be used

export const InputRevenueContext = createContext({
    revenueFormData: {},
    setRevenueFormData: () => {},
    revenueList: [],
    setRevenueList: () => {},
    revenueErrors: {},
    setRevenueErrors: () => {},
  });

export const InputRevenueProvider = ({ children }) => {

  // revenue data
  // ============
  const [revenueFormData, setRevenueFormData] = useState(initialRevenueForm);
  const [revenueList, setRevenueList] = useState([]);
  const [revenueErrors, setRevenueErrors] = useState({});

  const value = useMemo(() => ({
    revenueFormData,
    setRevenueFormData,
    revenueList,
    setRevenueList,
    revenueErrors,
    setRevenueErrors,
    initialRevenueForm
  }), [revenueFormData, revenueList, revenueErrors]);

  return (
    <InputRevenueContext.Provider value={value}>
      {children}
    </InputRevenueContext.Provider>
  );
};
