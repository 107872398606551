import React, {useContext, useMemo} from "react";
import {
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";

import { InputParametersContext } from "../../contexts/inputParameters.context";
import { InputPortfolioContext } from "../../contexts/inputPortfolio.context";
import { useTraceUpdate } from '../../utils/useTraceUpdate.utils';


// updateParameterList
//   parameterList: array of objects
//   name: key of the object to update
//   value: new value to set

function updateParameterList(parameterList, name, value) {
  const elementIndex = parameterList.findIndex((element) => element.name === name);
  const newParameterList = [...parameterList];
  newParameterList[elementIndex] = { name: name, value: value };
  return newParameterList;
}

// InputParameters
// do not fill a form with the parameter data (like the inputWithGrid component)
// Modify the parameterList directly. Therefor, no need for parameterFormData state variables

function InputParameters() {
  const debug = false;
  const _parent = "InputParameters";
  useTraceUpdate({_parent: _parent});

  const {
    parameterList,
    setParameterList,
    parameterErrors,
    setParameterErrors,
  } = useContext(InputParametersContext);

  const {
    accounts, 
  } = useContext(InputPortfolioContext);

  console.log("InputParameters - parameterList: ", parameterList);
  
/*
  // find the investAccount key from the parameterList array
  const investAccount = useMemo(() => {
    console.log("InputParameters - useMemo - parameterList: ", parameterList);
    const account = parameterList.find((element) => element.name === "invest.account");
    const person = parameterList.find((element) => element.name === "invest.person");
    const accountDisplay = account && person ? account.value + " / " + person.value : "";
    console.log("InputParameters - useMemo - accountDisplay: ", accountDisplay);
    return accountDisplay;
  }, [parameterList]);
*/

  // parameterDict
  // Create a dictionary from the parameterList array which is easier to use in the forms
  // parameterList is an array of objects with the format : 
  // [ { name: "invest.account", value: "accountName" },
  //   { name: "invest.person", value: "personName" },
  //   { name: "invest.percent", value: "50" }, ... ]
  // after each chane, create a dict with the format :
  // { 'invest.account': "accountName", 'invest.person': "personName", 'invest.percent': "50" }
  const parameterDict = useMemo(() => {
    const dict = {};
    parameterList.forEach((element) => {
      dict[element.name] = element.value;
    });
    console.log("InputParameters - useMemo - parameterDict: ", dict);
    return dict;
  }, [parameterList]);



  // form level handlers
  // ===================
  const handleInputChange = (e) => {
    // update parameterList with new value
    const { name, value } = e.target;
    if (name === "investAccount" ) {
      // split the value into account and person
      const [account, person] = value.split(" / ");
      // update the parameterList with the new values
      setParameterList(updateParameterList(parameterList, "invest.account", account));
      setParameterList(updateParameterList(parameterList, "invest.person", person));
    } else {
      setParameterList(updateParameterList(parameterList, name, value))
    }
  };

  if (parameterList === undefined) {
    debug && console.log("InputParameters - returning empty: ");
    return;
  }

  // -------------------------

  return (
    <Box
      sx={{ display: "flex", alignItems: "center" }}
      paddingLeft={2}
      paddingBottom={4}
    >
{/*      
      <FormControl fullWidth>
        <InputLabel shrink htmlFor="simulation-mode">
          Simulation Mode
        </InputLabel>
        <Select
          id="simulation-mode"
          name="simulationMode"
          value={parameterFormData.simulationMode}
          onChange={handleInputChange}
          label="Simulation Mode"
        >
          <MenuItem value="fixedGain">Fixed Gain</MenuItem>
          <MenuItem value="montecarlo">Monte Carlo</MenuItem>
          <MenuItem value="historical">Historical</MenuItem>
        </Select>
      </FormControl>
  
      <TextField
        fullWidth
        type="number"
        label="User Confidence Level (%)"
        name="userConfLevel"
        value={parameterFormData.userConfLevel}
        onChange={handleInputChange}
        error={!!parameterErrors.userConfLevel}
        helperText={parameterErrors.userConfLevel}
        inputProps={{
          min: 50,
          max: 98,
        }}
      />


      <TextField
        fullWidth
        type="number"
        label="Investment Percent"
        name="investPercent"
        value={parameterFormData.investPercent}
        onChange={handleInputChange}
        error={!!parameterErrors.investPercent}
        helperText={parameterErrors.investPercent}
        inputProps={{
          min: 0,
          max: 100,
        }}
      />
*/}
      <FormControl style={{ width: '300px' }}>
        <InputLabel htmlFor="invest-account">Compte d'investissement</InputLabel>
        {console.log("InputParameters - parameterList: ", parameterList)}
        {console.log("InputParameters - parameterDict: ", parameterDict)}
        {console.log("InputParameters - accounts: ", accounts)}
        <Select
          id="invest-account"
          name="investAccount"
          value={parameterDict['invest.account'] + " / " + parameterDict['invest.person']}
          onChange={handleInputChange}
        >
        {/* Populate the MenuItems with a list of : accounts.name + " / " + accounts.person */}
        {accounts.map((account, index) => {
            const accountDisplay = account.name + " / " + account.person;
            return (
            <MenuItem key={index} value={accountDisplay}>
              {accountDisplay}
            </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <FormControl >
      {console.log("InputParameters - investPercent: ", parameterDict['invest.percent'])}
        <TextField
          id="invest-percent"
          name="invest.percent"
          label="Pourcentage"
          type="number"
          value={parameterDict['invest.percent']}
          onChange={handleInputChange}
          inputProps={{
            min: 0,
            max: 100,
          }}
          error={!!parameterErrors.investPercent}
          helperText={parameterErrors.investPercent}
        />
      </FormControl>

    </Box>
  );
}

export default InputParameters;

/*
          {accounts.map((accountType) => (
            <MenuItem key={accountType} value={accountType}>
              {accountType}
            </MenuItem>
          ))}
*/