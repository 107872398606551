import React, { Fragment, useState, useContext, useMemo } from "react";
import { Box, useTheme } from "@mui/material";
import ResultHeading from "../components/resultHeading.component";

import OverviewEmergencyFunds from "../components/overviewEmergencyFunds.component";
import OverviewMainResults from "../components/overviewMainResults.component";
import OverviewSuggestions from "../components/overviewSuggestions.component";
import OverviewWithdrawalRates from "../components/overviewWithdrawalRates.component";
import NoSimulationData from '../components/noSimulationData.component';

import { SimulationDataContext } from "../contexts/simulationData.context";
import { PageHeading } from "../styles/heading.styles";

import OverviewButtons from "../components/overviewButtons.component";

// taxation
//  - tax optimisation
//  - tax bracket optimisation
//  - percentage paid in tax per year

/*
    resultSummary : 
    {
        "basicExpense": 4042220,
        "totalWithLuxury": 7770887,
        "netIncome": 3634053,
        "yearResultBasic": -408167,
        "yearResultWithLuxury": -4136834,
        "amountInvested": 0,
        "brutWithdrawn": 3536656,
        "simulationTime": "2024-09-06 18:53:40",
        "avgPercentWithdrawnFirst10y": 4.64,
        "maxPercentBrutWithdrawn": 65.78,
        "yearMaxPercentBrutWithdrawn": 2057,
        "failYear": 2058,
        "finalPortfolioValueBrut-Future": -1,
        "finalPortfolioValueNet-Future": -1,
        "finalPortfolioValueNet-Today": -1,
        "finalPortfolioValueBrut-Today": -1,
        "maxYear": 2067,
        "simCount": 0,
        "spendingAdjustmentThisCalc": 0,
        "minYear": 2023
    }


*/

const displayData = [
  {
    key: "maxYear",
    displayText: "Année finale",
    format: "none",
    panel: "mainResults",
  },
  {
    key: "failYear",
    displayText: "Année d'échec",
    format: "none",
    panel: "mainResults",
  },
  {
    key: "finalPortfolioValueBrut-Future",
    displayText: "Valeur finale du portefeuille (brute)",
    format: "thousandSeparator",
    panel: "mainResults",
  },
  {
    key: "finalPortfolioValueNet-Future",
    displayText: "Valeur finale du portefeuille (après impots)",
    format: "thousandSeparator",
    panel: "mainResults",
  },
  {
    // present value 
    key: "finalPortfolioValueBrut-Today",
    displayText: "Valeur finale du portefeuille (brute)",
    format: "thousandSeparator",
    panel: "mainResults",
  },
  {
    // present value 
    key: "finalPortfolioValueNet-Today",
    displayText: "Valeur finale du portefeuille (après impots)",
    format: "thousandSeparator",
    panel: "mainResults",
  },
  {
    key: "avgPercentWithdrawnFirst10y",
    displayText: "Taux de retrait moyen pendant les 10 premières années",
    format: "percent",
    panel: "withdrawalRates",
  },
  {
    key: "maxPercentBrutWithdrawn",
    displayText: "Taux de retrait maximum",
    format: "percent",
    panel: "withdrawalRates",
  },
  {
    key: "yearMaxPercentBrutWithdrawn",
    displayText: "Année du taux de retrait maximum",
    format: "none",
    panel: "withdrawalRates",
  },
];

export default function Overview() {
  const theme = useTheme();
  const [expanded, setExpanded] = useState([]);

  const { simulationData } = useContext(SimulationDataContext);

  console.log("OverviewHeader - simulationData", simulationData);

  // populate overviewData with relevant values from simulationData
  const overviewData = useMemo(() => {
    const data = [];

    // return if simulationData has not yet been populated
    const numKeys = Object.keys(simulationData).length;
    if (numKeys === 0) {
      return data;
    }

    const resultSummary = simulationData["resultSummary"];

    console.log("OverviewHeader - resultSummary", resultSummary);
    // update displayData with the values from simulationData
    // where the key in simulationData exists as a key in displayData
    displayData.forEach((row) => {
      if (resultSummary[row.key]) {
        data.push({
          ...row,
          value: resultSummary[row.key],
        });
      }
    });
    return data;
  }, [simulationData]);

  console.log("OverviewHeader - overviewData", overviewData);

  const handleCollapseAll = () => {
    setExpanded([]);
  };

  const handleExpandAll = () => {
    setExpanded(["withdrawalRates", "emergencyFunds", "suggestions"]);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      setExpanded([...expanded, panel]);
    } else {
      setExpanded(expanded.filter((p) => p !== panel));
    }
  };



  return (
    <Fragment>
        <ResultHeading 
          title="Résumé" 
          text=""
          textBefore=""
          navigateBefore=""
          textNext="Trésorie"
          navigateNext="/cashflow"
          />

      {/* Overview Main Results */}
      {console.log("OverviewHeader - overviewData", overviewData)}
      {overviewData && overviewData.length > 0 ? (
        <>
          <OverviewMainResults
            displayData={overviewData.filter((row) => row.panel === "mainResults")}
          />

          <OverviewButtons
            handleCollapseAll={handleCollapseAll}
            handleExpandAll={handleExpandAll}
            expanded={expanded}
            theme={theme}
          />


          <OverviewWithdrawalRates
            expanded={expanded.includes("withdrawalRates")}
            onChange={handleChange("withdrawalRates")}
            displayData={overviewData}
            panel="withdrawalRates"
          />

          {/* Overview Emergency funds */}
{/*

          <OverviewEmergencyFunds
            expanded={expanded.includes("emergencyFunds")}
            onChange={handleChange("emergencyFunds")}
            displayData={overviewData}
            panel="emergencyFunds"
          />
 */}

          {/* Overview Suggestions */}

{/*
          <OverviewSuggestions
            expanded={expanded.includes("suggestions")}
            onChange={handleChange("suggestions")}
          />
 */}
        </>
        ) : (
          <NoSimulationData />
        )
      }


    </Fragment>
  );
}
