import React, {Fragment, useState} from "react";
import PropTypes from 'prop-types';
import { Typography, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions  } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const InfoIconDialog = ({ heading, children }) => {

  const [open, setOpen] = useState(false);
  const handleClickOpen = (event) => {
    event.stopPropagation(); // This stops the event from propagating further
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  console.log( "InfoIconDialog - heading : ", heading, " - ", children)

  return (
    <Fragment>
      <IconButton onClick={handleClickOpen} style={{ color: 'blue', marginLeft: '30px', marginTop: '-5px' }}>
        <InfoIcon />
      </IconButton>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{heading}</DialogTitle>
          <DialogContent>
            <DialogContentText>
                {children}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Fermer
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
  );
};


export default InfoIconDialog; 
