import React, { useCallback, useState } from "react";

import {
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";

import AddBoxIcon from "@mui/icons-material/AddBox";
import { useTraceUpdate } from '../utils/useTraceUpdate.utils';

function DateWithPresetsInput({
  label,
  name,
  value,
  onChange,
  errors,
  disabled = false,
  parentId = null,   // Only used if the component is used in a table
  predefinedDates,
}) {

  const params = {label, name, value, onChange, errors, disabled, parentId, predefinedDates};
  useTraceUpdate({...params, _parent: "DateWithPresetsInput"});

  const [dateAnchorEl, setDateAnchorEl] = useState(null);

  const handleDateSelect = useCallback((date) => {
    setDateAnchorEl(null);
    onChange({ target: { name: name, value: date } }, parentId);
  }, [name, onChange, parentId]);

    const handleDateListClick = useCallback((event) => {
    setDateAnchorEl(event.currentTarget);
  }, []);


  const handleDateListClose = useCallback(() => {
    setDateAnchorEl(null);
  }, []);

  const handleFocus = useCallback((e) => {
    e.target.type = "date";
  }, []);

  const handleBlur = useCallback((e) => {
    e.target.type = "text";
  }, []);


  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginTop: -10,
      }}
    >
      <TextField
        fullWidth
        type="text"
        label={label}
        name={name}
        value={value}
        onChange={(event) => onChange(event, parentId)}
        InputProps={{ sx: { height: "2em" } }}
        InputLabelProps={{ sx: { top: "-35%" } }}
        onFocus={(e) => handleFocus(e)}
        onBlur={(e) => handleBlur(e)}
        error={errors && !!errors[name]}
        helperText={errors && errors[name]} 
        disabled={disabled}
      />
      <IconButton
        aria-label="Select Predefined Date"
        onClick={handleDateListClick}
        disabled={disabled}
      >
        <AddBoxIcon fontSize="large" />
      </IconButton>
      <Menu
        anchorEl={dateAnchorEl}
        open={Boolean(dateAnchorEl)}
        onClose={handleDateListClose}
        disabled={disabled}
      >
        {predefinedDates
          ? predefinedDates.map((date, index) => (
              <MenuItem key={index} onClick={() => handleDateSelect(date)}>
                {date}
              </MenuItem>
            ))
          : null}
      </Menu>
    </div>
  );
}

export {
  DateWithPresetsInput,
};
