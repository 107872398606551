import React, { Fragment, useContext, useMemo } from 'react';
import { useTheme } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { formatValue } from '../styles/tableComponents.styles';



// --------------------------------------------------------------------------------------------

export default function OverviewTable({displayData}) {
  
  const theme = useTheme();

  console.log('OverviewTable - displayData', displayData )

  return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 400, maxWidth: 600 }} size="small" aria-label="a dense table">
                    <TableBody>
                        {/* display data in table from resultSummary where the key in resultSummary exists as a key in displayData    */}  
                        {displayData.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.displayText}
                                </TableCell>
                                <TableCell align="right">
                                    {formatValue(row.value, row.format)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
  )
};